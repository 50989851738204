<template>
  <div class="intro-view--wrap container-fluid flex-grow-1 d-flex flex-column justify-content-center">
    <h1 class="text-center mb-5">
      {{ $t('intro.welcome') }} <br>
      {{ user.firstname }} {{ user.lastname }}
    </h1>

    <router-link
      :to="{ name: 'Categories' }"
      tag="button"
      class="btn btn-dark mx-auto d-flex align-items-center"
    >
      {{ $t('intro.goToPrices') }}
      <span class="material-icons ms-1">
        chevron_right
      </span>
    </router-link>

    <footer class="d-flex align-items-center justify-content-center text-white">
      {{ $t('infos.currentCatalog') }}
    </footer>
  </div>
</template>

<script>
export default {
  name: 'intro-view',

  computed: {
    user () {
      return this.$store.state.user
    },
  },
}
</script>

<style lang="scss">
  .intro-view--wrap {
    position: relative;
    background-image: url('~@/assets/gfx/backgrounds/Wing_Wand_cut.jpg');
    background-size: cover;
    background-position: 100% -40px;

    h1 {
      font-size: calc(1.775rem + 1.5vw);
    }

    footer {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: $navHeight;
      background-color: $dark;
    }
  }
</style>

<template>
  <div class="rod-length--wrap">
    <div class="input-group flex-nowrap">
      <div class="form-floating flex-grow-1">
        <input
          v-if="isRod"
          id="rodLength"
          type="number"
          :min="1"
          :step="1"
          :value="inputValue"
          class="form-control"
          inputmode="decimal"
          pattern="[0-9]*"
          @change="e => setRodLength(e.target.value)"
          @focus="e => e.target.select()"
        >
        <select
          v-else
          id="rodLength"
          class="form-select"
          @change="e => $emit('input', { key: 'RopeSelected', value: e.target.value })"
        >
          <option
            v-for="rope in config.RopeList"
            :key="`rope_${rope}`"
            :value="rope"
            :selected="rope === config.RopeSelected"
          >
            {{ rope }}
          </option>
        </select>

        <label
          for="rodLength"
          class="d-flex align-items-center mb-1"
        >
          {{ label }}

          <proxy-image
            v-if="config.NicheEnabled"
            src="/gfx/Nischenmass.svg"
            :alt="$t('article.niche')"
            class="niche-img img-fluid ms-3 my-2"
          />
        </label>
      </div>

      <span class="input-group-text">{{ units.measureUnit }}</span>
    </div>
  </div>
</template>

<script>
import ProxyImage from '@/components/ProxyImage'

export default {
  name: 'rod-length',

  components: {
    ProxyImage,
  },

  data () {
    return {
      wheelVisible: false,
    }
  },

  computed: {
    // current article-configuration
    config () {
      return this.$store.state.config
    },

    // value to use for the length-input as a number
    inputValue () {
      return parseFloat(this.config.RodLength.replace(',', '.'))
    },

    // rod-/rope-mode
    isRod () {
      return this.config.RopeSelected === ''
    },

    // label to use (since input-mode is defined by another config)
    label () {
      return this.config.NicheEnabled
        ? this.$t('article.niche')
        : this.isRod ? this.$t('article.rodLength') : this.$t('article.ropeLength')
    },

    // possible length-values preprared for the wheel-choice
    rodEntries () {
      return Array.from({ length: this.config.MaxRodLength - this.config.MinRodLength + 1 }, (_, k) => {
        const value = +this.config.MinRodLength + k
        return { label: `${value}${this.units.measureUnit}`, value }
      })
    },

    // units to use
    units () {
      return this.$store.state.units
    },
  },

  methods: {
    /**
     * The api expects decimal-numbers as a string with a comma.
     *
     * @param {number} length
     * @returns {void}
     */
    setRodLength (length) {
      this.$emit('input', {
        key: 'RodLength',
        value: length.toString().replace('.', ','),
      })
    },

    onWheelSelect ({ value }) {
      this.setRodLength(value)
      this.wheelVisible = false
    },
  },
}
</script>

<style lang="scss">
  .rod-length--wrap {
    .niche-img {
      max-height: 24px;
    }
  }
</style>

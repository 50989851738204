<template>
  <div
    ref="entryWrap"
    class="catalog-entry--wrap"
  >
    <bt-accordion
      :id="accodionId"
      parent="#categoryAccordion"
      :title="entry.Name"
      class="entry-accordion"
      @shown="scrollIntoView"
      @click:header="saveActiveGroup(entry)"
    >
      <template #prepend-header>
        <proxy-image
          v-if="entry.isWellenvorhang"
          src="/gfx/ico/ico_w_2A2D32.svg"
          class="img-fluid me-1 logo-w"
        />
      </template>

      <template #default>
        <div
          v-for="diameter in entry.Diameters"
          :key="diameter.Id"
          class="accordion accordion-flush"
        >
          <catalog-entry-group
            :id="`diameter_${entry.Id}_${diameter.Id}`"
            :groups="diameter.Groups"
            :parent="accodionId"
            :title="diameter.Name"
          />
          <catalog-entry-group
            v-for="ceiling in diameter.Ceiling"
            :id="`ceiling_${entry.Id}_${diameter.Id}_${ceiling.Id}`"
            :key="`ceiling_${entry.Id}_${diameter.Id}_${ceiling.Id}`"
            :groups="ceiling.Groups"
            :parent="accodionId"
            :title="ceiling.Name"
          />
        </div>
      </template>
    </bt-accordion>
  </div>
</template>

<script>
import BtAccordion from './BtAccordion'
import CatalogEntryGroup from '@/components/CatalogEntryGroup'
import ProxyImage from '@/components/ProxyImage'

export default {
  name: 'catalog-entry',

  components: {
    BtAccordion,
    CatalogEntryGroup,
    ProxyImage,
  },

  props: {
    entry: {
      type: Object,
      default: null,
      required: true,
    },
  },

  computed: {
    accodionId () {
      return `entry_${this.entry.Id}`
    },

    activeGroup () {
      return this.$store.state.activeGroup
    },
  },

  methods: {
    /**
     * When the state of an accordion changes we want to save that to be able
     * to open the last active one if the user navigates back to the category-
     * view.
     *
     * @param {object} entry dataset related to the accordion
     * @returns {void}
     */
    saveActiveGroup (entry) {
      const group = this.activeGroup && this.activeGroup === entry.Id ? null : entry.Id
      this.$store.commit('setActiveGroup', group)
    },

    /**
     * If an entry gets shown, we want to scroll that one to the top of the
     * view.
     *
     * @returns {void}
     */
    scrollIntoView () {
      this.$refs.entryWrap.scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>

<style lang="scss">
  .catalog-entry--wrap {
    border-bottom: 1px solid $gray-400;

    &:last-child {
      border-bottom: none;
    }

    .logo-w {
      max-width: 32px;
    }

    .entry-accordion {
      transition: all .35s cubic-bezier(.25,.8,.5,1);

      .accordion-button,
      .accordion-collapse {
        box-shadow: none;
        transition: all .35s cubic-bezier(.25,.8,.5,1);
      }

      &.expanded {
        margin: 16px;

        > .accordion-header {
          .accordion-button {
            background-color: $gray-900;
            color: #fff;
            border-radius: 4px 4px 0 0;

            img {
              filter: invert(1);
            }

            &::after {
              filter: grayscale(1) brightness(4);
            }
          }
        }

        > .accordion-collapse {
          background-color: $gray-300;
          box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12);
        }
      }
    }
  }
</style>

<template>
  <img :src="url">
</template>

<script>
import Api from '@/api'

export default {
  name: 'proxy-img',

  props: {
    src: {
      type: String,
      default: '',
      required: true,
    },
  },

  computed: {
    url () {
      return `${Api.baseUrl}${this.src}`
    }
  },
}
</script>

<template>
  <div class="variant-choice--wrap">
    <image-choice class="mb-3" />
    <article-variants />
  </div>
</template>

<script>
import ArticleVariants from './ArticleVariants'
import ImageChoice from './ImageChoice'

export default {
  name: 'variant-choice',

  components: {
    ArticleVariants,
    ImageChoice,
  }
}
</script>

export default {
  'de-DE': {
    login: {
      login: 'Anmelden',
      email: 'E-Mail',
      password: 'Passwort',
      register: 'Registrieren',
      error: {
        userLocked: 'Der Benutzer ist zurzeit gesperrt. Bitte wenden Sie sich an unseren Support.',
        loginWrong: 'Die Anmeldedaten sind nicht korrekt.',
      }
    },
    infoPages: {
      agb: 'AGB',
      imprint: 'Impressum',
      privacy: 'Datenschutz',
    },
    intro: {
      goToPrices: 'Weiter',
      welcome: 'Willkommen,',
    },
    categories: {
      headline: 'Garnituren',
    },
    article: {
      basicOptions: 'Allgemeine Optionen',
      bracket: 'Trägerlänge',
      ceilingBracket: {
        wall: 'Wand',
        ceiling: 'Decke'
      },
      color: 'Farbe',
      components: 'Stückliste',
      config: 'Konfiguration',
      cordOptions: {
        title: 'Schnurzug',
        height: 'Bedienhöhe',
        curtainSplit: 'Vorhangteilung',
        splitOptions: {
          cordTL: 'links',
          cordTM: 'mittig',
          cordTR: 'rechts',
          none: 'ohne'
        }
      },
      endPieces: {
        choice: 'Aktuelle Auswahl:',
        choose: 'Auswählen',
        edit: 'Bearbeiten',
        title: 'Endstückauswahl',
        directions: {
          Both: 'beidseitig',
          Left: 'nur links',
          Right: 'nur rechts',
          Without: 'ohne'
        },
      },
      error: 'Fehler',
      errors: {
        RodParted: 'Die Stange wurde aufgrund der Länge automatisch geteilt.',
        RodToLong: 'Die eingegebene Stangenlänge überschreitet die Maximallänge von {0}, deshalb wird sie auf den letzten gültigen Wert zurückgesetzt.',
        RodToShort: 'Die eingegebene Stangenlänge unterschreitet die Minimallänge von {0}, deshalb wird sie auf den letzten gültigen Wert zurückgesetzt.',
        RodLengthInvalid: 'Die eingegebene Stangenlänge ist nicht gültig. Bitte wählen Sie die Stangenlänge in {0}-Schritten.',
        SegmentTooShort: 'Die eingegebene Segmentlänge unterschreitet die Minimallänge von {0}, deshalb wird sie auf den letzten gültigen Wert zurückgesetzt.',
        SegmentTooLong: 'Die eingegebene Segmentlänge überschreitet die Maximallänge von {0}, deshalb wird sie auf den letzten gültigen Wert zurückgesetzt.',
        Error: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
      },
      integral: {
        description: 'Mehr Bewegungsspielraum: Die integral-Technik vergrößert die Abstände zwischen den Trägern. Dadurch lassen sich größere Spannweiten ohne Mittelträger überbrücken. Ein in der Stange versteckt verlaufener Draht lässt sich stufenlos spannen, bis die Stange gerade ist. Eine ideale Lösung für größere Spannweiten, schwere Stoffe und räumliche Gegebenheiten, in denen man keine Mittelträger setzen möchte, wie z.B. am Rolladenkasten. Dabei bleiben die Spannkräfte im Rohr und werden nicht auf die Träger übertragen.',
        title: 'Integral',
        with: 'mit',
        without: 'ohne',
      },
      niche: 'Nische',
      panelCarriage: {
        add: 'Hinzufügen',
        added: 'Hinzugefügt',
        addedInfo: 'Ihre Auswahl wurde hinzugefügt',
        amount: 'Anzahl',
        articleNr: 'Art-Nr.',
        color: 'Farbe',
        currentChoice: 'Aktuelle Auswahl:',
        length: 'Länge',
        title: 'Paneelwagen',
        without: 'Ohne',
      },
      resetDialog: {
        cancel: 'Abbrechen',
        title: 'Konfiguration zurücksetzen',
        info: 'Sie können den aktuell ausgewählten Artikel zurücksetzen. Dadurch wird Ihre getätigte Konfiguration verworfen.',
        reset: 'Zurücksetzen',
      },
      restoreErrors: {
        title: 'Es wurden Einstellungen zurückgesetzt',
        info: 'Die Anpassung der Konfiguration erforderte ein Zurücksetzen getätigter Einstellungen. Betroffen sind:',
        keys: {
          BracketSelected: 'Trägerlänge',
          FinialSelected: 'Endstückauswahl',
          PartList: 'Stückliste',
          RingSelected: 'Ring',
          RingColorSelected: 'Ringfarbe'
        }
      },
      ringChoice: 'Gleiter / Ringe',
      rodLength: 'Stangenlänge',
      rodVariant: 'Variante',
      rodVariantSuffix: '-läufig',
      ropeLength: 'Seillänge',
      socket: {
        label: 'Wandlager',
        without: 'ohne',
        left: 'nur links',
        right: 'nur rechts',
        both: 'beidseitig'
      },
      variant: 'Modell',
      variants: {
        bracket: 'Träger',
        operatingSide: 'Bedienseite',
        ceilingBracket: 'Montage',
        rod: 'Rohr/Profil',
        rodCount: 'Anzahl Läufe',
        wall: 'Wandanschluss'
      },
      variantWarnings: {
        bracket: 'Der ausgewählte Träger kann in der derzeitigen Konfiguration nicht verwendet werden.',
        operatingSide: 'Die ausgewählte Bedienseite kann in der derzeitigen Konfiguration nicht verwendet werden.',
        ceilingBracket: 'Die ausgewählte Montage kann in der derzeitigen Konfiguration nicht verwendet werden.',
        rod: 'Das ausgewählte Rohr/Profil kann in der derzeitigen Konfiguration nicht verwendet werden.',
        rodCount: 'Die ausgewählte Anzahl Läufe kann in der derzeitigen Konfiguration nicht verwendet werden.',
        wall: 'Der ausgewählte Wandanschluss kann in der derzeitigen Konfiguration nicht verwendet werden.'
      },
      withoutRings: 'Ohne Gleiter / Ringe',
    },
    assembly: {
      title: 'Informationen',
      assemblyInstruction: 'Montageanleitung Garnitur',
      assemblyIntegralInstruction: 'Integral Montageanleitung',
      productInfo: 'Produktinformation',
      assemblyVideo: 'Montagevideo ansehen'
    },
    loadData: 'Daten werden geladen',
    favorites: {
      addCurrent: 'Aktuellen Artikel ergänzen',
      title: 'Favoriten',
      missing: 'Keine Favoriten vorhanden',
    },
    register: {
      company: 'Firma',
      customerId: 'Kundennummer',
      email: 'E-Mail',
      emailConfirm: 'E-Mail (Wiederholung)',
      error: 'Ein Fehler ist aufgetreten',
      firstname: 'Vorname',
      info: 'Das interstil Fachhändlerportal richtet sich an die Fachhändler von interstil. Sollten Sie interesse daran haben, mit Ihrer Firma ebenfalls ein Fachhändler von interstil zu werden, können Sie sich bei uns telefonisch oder über interstil.de melden.',
      lastname: 'Nachname',
      register: 'Registrieren',
      success: 'Registrierung erfolgreich',
      successHint: 'Ihre Anfrage wird zeitnah geprüft.',
      title: 'Registrierung Ihres interstil-Accounts',
      zip: 'PLZ',
    },
    activation: {
      activate: 'Aktivieren',
      confirmation: 'Passwort (Wiederholung)',
      error: 'Aktivierung fehlgeschlagen',
      password: 'Passwort',
      passwordError: 'Bitte legen Sie ein Passwort fest und wiederholen dieses.',
      success: 'Aktivierung erfolgreich',
      successHint: 'Sie können sich nun anmelden.',
      title: 'interstil-Account aktivieren',
    },
    search: {
      title: 'Suche',
      inputPlaceholder: 'Bezeichnung / Artikel-Nr.'
    },
    infos: {
      currentCatalog: 'Aktuelle VK-Preise 05/2023',
      pageInfoTitle: 'Art.-Nr.',
      pageInfo: 'Im Katalog interstil, ab Seite.',
      pageInfoF1: 'Im Katalog F1, ab Seite',
      pageInfoW: 'Im Katalog W - Wellenvorhang, ab Seite',
      priceInfo: 'inkl. Mehrwertsteuer. Irrtümer vorbehalten.',
      priceInfoTitle: 'Preis gilt für eine Garnitur',
      surcharge: 'Preise 15% über gedruckten Katalogpreisen',
    },
    userConfigs: {
      cancelDeletion: 'Abbrechen',
      configTitlePlaceholder: 'Bezeichnung der Konfiguration',
      confirmDeletion: 'Konfiguration löschen',
      noSavedConfigs: 'Keine Konfigurationen vorhanden',
      save: 'Aktuelle Konfiguration speichern',
      title: 'Meine Konfigurationen',
    },
    wheel: {
      cancel: 'Abbrechen',
      error: 'Ungültige Eingabe',
      maxExceeded: 'Maximum überschritten',
      minExceeded: 'Minimum überschritten',
      save: 'Speichern',
    },
  },
}

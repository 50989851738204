<template>
  <div
    :id="`group_${id}`"
    ref="groupWrap"
    class="catalog-entry-group--wrap"
  >
    <bt-accordion
      :id="id"
      :title="title"
      :parent="`#${parent}`"
      @shown="scrollIntoView"
      @click:header="saveActiveDiameter()"
    >
      <div
        v-for="group in groupsWithUniqueArticles"
        :key="group.id"
      >
        <div
          v-for="(article, i) in group.Articles"
          :key="i"
          class="group-entry d-flex justify-content-between align-items-center px-4 py-2"
          @click="goToArticle(article.ArticleNumber, article.DefaultColor)"
        >
          <div>
            {{ article.ArticleName }}

            <proxy-image
              :src="`/images/${article.Image}`"
              class="img-fluid mt-2"
              :alt="group.Name"
            />
          </div>
        </div>
      </div>
    </bt-accordion>
  </div>
</template>

<script>
import BtAccordion from './BtAccordion'
import ProxyImage from '@/components/ProxyImage'

export default {
  name: 'catalog-entry-group',

  components: {
    BtAccordion,
    ProxyImage,
  },

  props: {
    groups: {
      type: Array,
      default: () => ([])
    },

    id: {
      type: String,
      default: '',
    },

    parent: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    activeDiameter () {
      return this.$store.state.activeDiameter
    },

    /**
     * An article-group can have multiple articles with the same article-number.
     * If that's the case, we just want to show the first one oh those.
     *
     * @returns {array}
     */
    groupsWithUniqueArticles () {
      return this.groups.map(group => ({
        ...group,
        Articles: group.Articles.reduce((articles, article) =>
          articles.find(({ ArticleNumber }) => ArticleNumber === article.ArticleNumber)
            ? articles
            : [...articles, article]
        , [])
      }))
    },
  },

  methods: {
    /**
     * Redirects to the detail-page of the article with the given articlenumber
     * and color-variant.
     *
     * @param {string} articleNr
     * @param {string} color
     * @returns {void}
     */
    goToArticle (articleNr, color) {
      this.$router.push({
        name: 'Article',
        params: { articleNr, color }
      })
    },

    /**
     * When the state of an accordion changes we want to save that to be able
     * to open the last active one if the user navigates back to the category-
     * view.
     *
     * @returns {void}
     */
    saveActiveDiameter () {
      const diameter = this.activeDiameter && this.activeDiameter === this.id ? null : this.id
      this.$store.commit('setActiveDiameter', diameter)
    },

    /**
     * If an entry gets shown, we want to scroll that one to the top of the
     * view.
     *
     * @returns {void}
     */
    scrollIntoView () {
      this.$refs.groupWrap.scrollIntoView({ behavior: 'smooth' })
    }
  },
}
</script>

<style lang="scss">
  .catalog-entry-group--wrap {
    border-bottom: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;

    .accordion-button {
      background-color: $gray-200;
    }

    .group-entry {
      background-color: $white;
      border-bottom: 1px solid $gray-400;
    }
  }
</style>

<template>
  <div
    v-if="config.PossiblePanelCarriages.length > 0"
    class="panel-carriage--wrap"
  >
    <showcase-card>
      <template #title>
        {{ $t('article.panelCarriage.title') }}
      </template>

      <template #title-append>
        <button
          class="btn btn-sm p-0 d-flex align-items-center"
          @click="showAddDialog = true"
        >
          {{ $t('article.panelCarriage.add') }}

          <span class="material-icons ms-2">
            add_circle
          </span>
        </button>
      </template>

      <template #content>
        <div v-if="config.SelectedPanelCarriages.length === 0">
          {{ $t('article.panelCarriage.currentChoice') }} {{ $t('article.panelCarriage.without') }}
        </div>

        <chosen-panel
          v-for="(panel, i) in config.SelectedPanelCarriages"
          :key="`chosenPanel_${i}`"
          :panel="panel"
          :class="{ 'mb-4': i < config.SelectedPanelCarriages.length - 1 }"
        />
      </template>
    </showcase-card>

    <bt-modal
      :is-visible="showAddDialog"
      :title="$t('article.panelCarriage.title')"
      @close="showAddDialog = false"
    >
      <div
        v-for="panelCarriage in config.PossiblePanelCarriages"
        :key="panelCarriage.ArticleNr"
        class="mb-4"
      >
        <div
          class="form-check"
        >
          <input
            :id="`panelCarriage-${panelCarriage.ArticleNr}`"
            class="form-check-input"
            type="radio"
            :checked="panelIsActive(panelCarriage)"
            @change="selectedPanel = panelCarriage"
          >
          <label
            class="form-check-label fw-bold"
            :for="`panelCarriage-${panelCarriage.ArticleNr}`"
          >
            {{ panelCarriage.ArticleName }}
            <proxy-image
              :src="`/images/${panelCarriage.Picture}`"
              class="img-fluid mt-2"
            />
          </label>
        </div>

        <panel-config
          :key="`${panelCarriage.ArticleNr}_config`"
          :panel="selectedPanel"
          class="mt-4"
          :class="{ 'd-none': !panelIsActive(panelCarriage) }"
        />
      </div>
    </bt-modal>
  </div>
</template>

<script>
import BtModal from '@/components/BtModal.vue'
import ChosenPanel from './ChosenPanel'
import ProxyImage from '@/components/ProxyImage'
import ShowcaseCard from '@/components/ShowcaseCard'
import PanelConfig from './PanelConfig'

export default {
  name: 'panel-carriage',

  components: {
    BtModal,
    ChosenPanel,
    ProxyImage,
    ShowcaseCard,
    PanelConfig,
  },

  data () {
    return {
      selectedPanel: null,
      showAddDialog: false,
    }
  },

  computed: {
    config () {
      return this.$store.state.config
    },
  },

  methods: {
    panelIsActive (panel) {
      return this.selectedPanel && this.selectedPanel.ArticleNr === panel.ArticleNr
    }
  }
}
</script>

export default function (fn, timeout = 300) {
  let timer

  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(this, args)
    }, timeout)
  }
}

<template>
  <div
    v-if="variants.length > 0 || article.RodCountVariants.length"
    class="article-variants--wrap"
  >
    <showcase-card class="mb-3">
      <template #title>
        {{ $t('article.variant') }}
      </template>

      <template #content>
        <rod-choice
          v-if="article.RodCountVariants"
          :class="{ 'mb-2': variantsWithOptions.length > 0 }"
          @input="count => updateVariant({ key: 'SelectedRodCountVariant', translation: 'rodCount' }, count)"
        />

        <ceiling-choice
          v-if="article.EnableCeilingAndWallBracket"
          class="mb-2"
          @input="ceilingSelected => updateVariant({
            key: 'CeilingBracketSelected',
            translation: 'ceilingBracket'
          }, ceilingSelected === 'true')"
        />

        <template v-for="(variant, i) in variantsWithOptions">
          <div
            v-if="!variant.hide"
            :key="i"
            class="variant--wrap form-floating"
            :class="{ 'mb-2': i < variantsWithOptions.length - 1 }"
          >
            <select
              :id="variant.key"
              :value="article[variant.key]"
              class="form-select"
              @input="e => updateVariant(variant, e.target.value)"
            >
              <option
                v-for="(option, x) in variant.options"
                :key="`${variant.key}_${x}`"
                :value="option"
                :selected="option === article[variant.key]"
              >
                {{ option }}
              </option>
            </select>

            <label :for="variant.key">
              {{ $t(`article.variants.${variant.translation}`) }}
            </label>
          </div>
        </template>
      </template>
    </showcase-card>

    <bt-toast ref="toast" />
  </div>
</template>

<script>
import Api from '@/api'

import BtToast from '@/components/BtToast'
import CeilingChoice from './CeilingChoice'
import RodChoice from './RodChoice'
import ShowcaseCard from '@/components/ShowcaseCard'

export default {
  name: 'article-variants',

  components: {
    BtToast,
    CeilingChoice,
    RodChoice,
    ShowcaseCard,
  },

  computed: {
    // currently loaded article
    article () {
      return this.$store.state.article
    },

    // currently chosen color of the article
    color () {
      return this.$store.state.color
    },

    // all possible variants
    variants () {
      const variants = [
        {
          key: 'SelectedBracketVariant',
          options: this.article.BracketVariants,
          translation: 'bracket',
        },
        {
          key: 'SelectedOperatingSideVariant',
          options: this.article.OperatingSideVariants,
          translation: 'operatingSide',
        },
        {
          key: 'SelectedRodCountVariant',
          options: this.article.RodCountVariants,
          translation: 'rodCount',
          hide: true,
        },
        {
          key: 'SelectedRodVariant',
          options: this.article.RodVariants,
          translation: 'rod',
        },
        {
          key: 'SelectedWallVariant',
          options: this.article.WallVariants,
          translation: 'wall',
        },
      ]

      /**
       * Sadly a special-case:
       *   - enabled/disbled by the current article
       *   - uses fixed options instead of values from the api
       *   - choice handled by a child-component
       */
      if (this.article.EnableCeilingAndWallBracket) {
        variants.push({ key: 'CeilingBracketSelected', hide: true, options: [] })
      }

      return variants
    },

    // variants that have options to choose from
    variantsWithOptions () {
      return this.variants.filter(({ options }) => options.length)
    }
  },

  methods: {
    /**
     * Requests an article-update based on the given variant, chosen value.
     * If the variant isn't valid we don't get an error - so we have to compare
     * the desired value with the updated article to show a hint if those don't
     * match.
     *
     * @param {object} variant
     * @param {string} value
     * @returns {void}
     */
    async updateVariant ({ key, translation }, value) {
      this.$store.commit('setLoading', true)

      const variantConfig = this.variants.reduce((variantConfig, variant) => {
        variantConfig[variant.key] = variant.key === key ? value : this.article[variant.key]
        return variantConfig
      }, { ArticleNr: this.article.Articlenr })

      const updatedArticle = await Api.setVariant(variantConfig)
      this.$store.commit('setLoading', false)

      if (updatedArticle[key] !== value) {
        this.$store.commit('setArticle', updatedArticle)
        this.$refs.toast.show(
          this.$t('article.error'),
          this.$t(`article.variantWarnings.${translation}`)
        )

        return
      }

      this.$router.replace({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          articleNr: updatedArticle.Articlenr,
          color: this.getColorToUse(updatedArticle.Colors),
        },
      })
    },

    /**
     * When the variant of an article gets changed, available colors may change.
     * If the previously chosen one is still available, we want to use that
     * again - otherwise simply the first one.
     *
     * @param {array} colors Available colors of the updated articke-variant
     * @returns {string} Key of the color to use
     */
    getColorToUse (colors = []) {
      const colorStillAvailable = colors.find(({ Key }) => Key === this.color) !== undefined
      return colorStillAvailable ? this.color : colors[0].Key
    },
  },
}
</script>

<template>
  <div class="ceiling-choice--wrap">
    <div class="form-floating flex-grow-1">
      <select
        id="ceilingSelect"
        class="form-select"
        :value="ceilingSelected"
        @input="e => $emit('input', e.target.value)"
      >
        <option
          v-for="({ label, value }, i) in options"
          :key="i"
          :value="value"
          :selected="value === ceilingSelected"
        >
          {{ label }}
        </option>
      </select>

      <label for="ceilingSelect">
        {{ $t('article.variants.ceilingBracket') }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ceiling-choice',

  data () {
    return {
      options: [
        { label: this.$t('article.ceilingBracket.wall'), value: false },
        { label: this.$t('article.ceilingBracket.ceiling'), value: true },
      ]
    }
  },

  computed: {
    // ceiling is currently selected
    ceilingSelected () {
      return this.$store.state.article.CeilingBracketSelected
    },
  },
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'

import ActivateView from '@/views/ActivateView'
import ArticleIndex from '@/views/Article/Index'
import CategoryView from '@/views/CategoryView'
import IntroView from '@/views/IntroView'
import LoginView from '@/views/LoginView'
import RegisterView from '@/views/RegisterView'

import InfoIndex from '@/views/Info/Index'
import Agb from '@/views/Info/Agb'
import Imprint from '@/views/Info/Imprint'
import Privacy from '@/views/Info/Privacy'

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/activate/:id',
    name: 'Activate',
    component: ActivateView,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/',
    name: 'Intro',
    component: IntroView,
  },
  {
    path: '/garnituren',
    name: 'Categories',
    component: CategoryView,
  },
  {
    path: '/artikel/:articleNr/:color?/:configId?',
    name: 'Article',
    component: ArticleIndex,
  },
  {
    path: '/info',
    name: 'Info',
    component: InfoIndex,
    children: [
      {
        path: 'agb',
        name: 'Agb',
        component: Agb,
      },
      {
        path: 'impressum',
        name: 'Imprint',
        component: Imprint,
      },
      {
        path: 'datenschutz',
        name: 'Privacy',
        component: Privacy,
      },
    ],
  },
]

/**
 * In our layout an inner element is the scrollable one. The vertical position
 * of that should get reset when the user switches the route (so the upper
 * content is visible).
 * But that shouldn't happen within the article-view since there may occur
 * route-changes without leaving the view (e.g. when changing the color) - a
 * reset in that situation would annoy users.
 *
 * @param {object} to
 * @param {object} from
 * @returns {void}
 */
const scrollBehavior = (to, from) => {
  if (to.name === 'Article' && from.name === 'Article') {
    return false
  }

  const mainContent = document.querySelector('main')
  mainContent && (mainContent.scrollTop = 0)
}

const router = new VueRouter({
  routes,
  scrollBehavior,
})

export default router

<template>
  <div
    v-if="config"
    class="config-choice--wrap"
  >
    <showcase-card class="mb-3">
      <template #title>
        {{ $t('article.basicOptions') }}
      </template>

      <template #content>
        <socket-choice
          class="mb-2"
          @input="direction => updateConfig('SocketDirection', direction)"
        />
        <rod-length
          class="mb-2"
          @input="({ key, value }) => updateConfig(key, value)"
        />
        <bracket-choice
          @input="bracket => updateConfig('BracketSelected', bracket)"
        />
      </template>
    </showcase-card>

    <ring-choice
      class="mb-3"
      @input="({ key, value }) => updateConfig(key, value)"
    />

    <integral-choice
      class="mb-3"
      @input="({ key, value }) => updateConfig(key, value)"
    />

    <panel-carriage-choice class="mb-3" />

    <end-pieces
      class="mb-3"
      @input="updateEndPieces"
    />

    <cord-options
      class="mb-3"
      @input="({ key, value }) => updateConfig(key, value)"
    />

    <bt-toast ref="toast" />
  </div>
</template>

<script>
import Api from '@/api'

import BracketChoice from './BracketChoice'
import BtToast from '@/components/BtToast'
import CordOptions from './CordOptions'
import EndPieces from './EndPieces'
import IntegralChoice from './IntegralChoice'
import PanelCarriageChoice from './PanelCarriageChoice/Index'
import RingChoice from './RingChoice'
import RodLength from './RodLength'
import ShowcaseCard from '@/components/ShowcaseCard'
import SocketChoice from './SocketChoice'

export default {
  name: 'config-choice',

  components: {
    BracketChoice,
    BtToast,
    CordOptions,
    EndPieces,
    IntegralChoice,
    PanelCarriageChoice,
    RingChoice,
    RodLength,
    ShowcaseCard,
    SocketChoice,
  },

  computed: {
    // current article-configuration
    config () {
      return this.$store.state.config
    },

    // units to use
    units () {
      return this.$store.state.units
    },
  },

  methods: {
    /**
     * Requests a configuration-update for the given key-value-pair.
     *
     * @param {string} key
     * @param {any} value
     * @returns {Promise}
     */
    async updateConfig (key, value) {
      this.$store.commit('setLoading', true)
      const res = await Api.postConfig(key, value)
      this.$store.commit('setLoading', false)

      if (res.ok) {
        this.$store.commit('setConfig', await res.json())
        this.fixSegments()
      }

      if (res.status === 406) {
        const config = await res.json()
        this.$store.commit('setConfig', config)
        this.displayConfigError(config.ReturnCode)
      }
    },

    /**
     * Picks the translation for the given error-code, adds the valid mix-/max-
     * value.
     *
     * @param {string} error
     * @returns {void}
     */
    displayConfigError (error) {
      let key

      if (['RodToLong', 'SegmentTooLong'].includes(error)) {
        key = 'MaxRodLength'
      } else if (['RodToShort', 'SegmentTooShort'].includes(error)) {
        key = 'MinRodLength'
      } else if (error === 'RodLengthInvalid') {
        key = 'RodLengthIncrement'
      }

      this.$refs.toast.show(
        this.$t('article.error'),
        this.$t(`article.errors.${error}`, [`${this.config[key]} ${this.units.measureUnit}`])
      )
    },

    /**
     * The API automatically splits the rod into segments if the length is too
     * long. If the user selects a smaller length after that, the segmentation
     * remains which leads to a different price.
     *
     * Example:
     *  - 200cm is initially chosen, then 1200cm gets selected
     *  - API splits the rod into three segments
     *  - 200cm is chosen again, the segmentation remains
     *  - the price differs from the initial one
     *
     * So we try to select the smallest allowed segmentation for the current
     * rod-length.
     *
     * @returns {void}
     */
    fixSegments () {
      const segmentVariants = ['T0Enabled', 'T1Enabled', 'T2Enabled']
      const smallestAllowedSegment = segmentVariants.find(key => this.config[key])
      const segmentIndex = segmentVariants.indexOf(smallestAllowedSegment)

      if (segmentIndex + 1 < this.config.Segments.length) {
        this.updateConfig(`Part${segmentIndex}`, true)
      }
    },

    /**
     * Requests an update of the endpiece-choice.
     *
     * @param {object} update
     * @param {string} update.id
     * @param {string} update.direction
     * @returns {void}
     */
    async updateEndPieces ({ id, direction }) {
      await this.updateConfig('FinialSelected', id)
      this.updateConfig('FinialDirection', direction)
    },
  },
}
</script>

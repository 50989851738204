<template>
  <bt-modal
    :title="$t('search.title')"
    :is-visible="value"
    class="search-dialog"
    @close="$emit('input', false)"
  >
    <div class="input-group">
      <input
        v-model="search"
        type="text"
        class="form-control"
        :placeholder="$t('search.inputPlaceholder')"
      >
      <button
        class="btn btn-outline-secondary d-flex align-items-center"
        type="button"
        @click="search = ''"
      >
        <span class="material-icons pa-2">
          delete
        </span>
      </button>
    </div>

    <div
      v-if="filteredArticles.length > 0"
      class="mt-3"
    >
      <div
        v-for="({ ArticleName, ArticleNr }) in filteredArticles"
        :key="ArticleNr"
        class="search-entry py-2 d-flex justify-content-between"
        @click="goToArticle(ArticleNr)"
      >
        <text-highlight
          :text="ArticleName"
          :search="search"
        />

        <span class="ms-2">
          #<text-highlight
            :text="ArticleNr"
            :search="search"
          />
        </span>
      </div>
    </div>
  </bt-modal>
</template>

<script>
import BtModal from '@/components/BtModal'
import TextHighlight from '@/components/TextHighlight'

export default {
  name: 'search-dialog',

  components: {
    BtModal,
    TextHighlight,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      search: '',
    }
  },

  computed: {
    // all articles
    articles () {
      return this.$store.state.articles
    },

    /**
     * Articles that match the current search (based on the articlename, -nr)
     * without duplicated names.
     * If there aren't any results, we want to check the basevariant of the
     * as an alternative and use that as the name to search for (background:
     * users want to search for things like "Flächenvorhang" or "F1" which
     * aren't used in the articlename).
     *
     * @returns {array}
     */
    filteredArticles () {
      if (!this.search) {
        return []
      }

      const search = text => text.toLowerCase().includes(this.search.toLowerCase())
      let articles = this.articles.filter(({ ArticleName, ArticleNr }) => search(ArticleName) || search(ArticleNr))

      if (!articles.length) {
        articles = this.articles
          .filter(({ BaseVariantName }) => search(BaseVariantName))
          .map(article => ({ ...article, ArticleName: article.BaseVariantName }))
      }

      return articles.reduce((items, article) =>
        items.find(item => item.ArticleName === article.ArticleName) === undefined ? [...items, article] : items
      , [])
    },
  },

  methods: {
    /**
     * Redirects to the detail-page of an article with the given articlenumber
     * (if we're not there already), closes the dialog.
     *
     * @param {string} articleNr
     * @returns {void}
     */
    goToArticle (articleNr) {
      const currentArticle = this.$route.params.articleNr

      if (!currentArticle || currentArticle !== articleNr) {
        this.$router.replace({ name: 'Article', params: { articleNr } })
      }

      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss">
  .search-dialog {
    .search-entry {
      border-bottom: 1px solid $gray-200;

      &:last-child {
        border-bottom: none;
      }
    }
  }
</style>

<template>
  <div class="ring-choice--wrap">
    <showcase-card v-if="config.RingList.length > 0">
      <template #title>
        {{ $t('article.ringChoice') }}
      </template>

      <template #title-append>
        <div class="form-check form-switch">
          <input
            id="withoutRings"
            class="form-check-input"
            type="checkbox"
            role="switch"
            :checked="config.RingWithout"
            @change="e => $emit('input', { key: 'RingWithout', value: e.target.checked })"
          >
          <label
            class="form-check-label"
            for="withoutRings"
          >
            {{ $t('article.withoutRings') }}
          </label>
        </div>
      </template>

      <template
        v-if="!config.RingWithout"
        #content
      >
        <div class="row">
          <div class="col-7">
            <div
              v-if="config.RingColorList.length > 1"
              class="form-floating"
            >
              <select
                id="ringColor"
                class="form-select mb-2"
                @input="e => $emit('input', { key: 'RingColorSelected', value: e.target.value })"
              >
                <option
                  v-for="(color, i) in config.RingColorList"
                  :key="i"
                  :value="color"
                  :selected="color == config.RingColorSelected"
                >
                  {{ color }}
                </option>
              </select>
              <label
                for="ringColor"
                class="mb-1"
              >
                {{ $t('article.color') }}
              </label>
            </div>

            <div
              v-for="(ring, i) in config.RingList"
              :key="`ring_${i}`"
              class="form-check"
              :class="{ 'mb-2': i < config.RingList.length - 1 }"
            >
              <input
                :id="`ring_${i}`"
                class="form-check-input"
                type="radio"
                :checked="ring.Key === config.RingSelected"
                :value="ring.Key"
                @change="e => $emit('input', { key: 'RingSelected', value: e.target.value })"
              >
              <label
                class="form-check-label"
                :for="`ring_${i}`"
              >
                {{ ring.Key }}
              </label>
            </div>
          </div>

          <div class="col-5 d-flex align-center">
            <div
              v-if="!config.RingWithout"
              class="optionElementImage"
            >
              <proxy-image
                :src="ringImage"
                :alt="config.RingSelected"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </template>
    </showcase-card>
  </div>
</template>

<script>
import ProxyImage from '@/components/ProxyImage'
import ShowcaseCard from '@/components/ShowcaseCard'

export default {
  name: 'ring-choice',

  components: {
    ProxyImage,
    ShowcaseCard,
  },

  computed: {
    // current article-configuration
    config () {
      return this.$store.state.config
    },

    // path to the image of the currently selected ring
    ringImage () {
      const selectedRing = this.config.RingList.find(ring => ring.Key === this.config.RingSelected)
      return selectedRing ? `/images/${selectedRing.Value}` : null
    },
  },
}
</script>

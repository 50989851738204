<template>
  <div
    v-if="config.CordEnabled"
    class="cord-options--wrap"
  >
    <showcase-card>
      <template #title>
        {{ $t('article.cordOptions.title') }}
      </template>

      <template #content>
        <div class="input-group flex-nowrap mb-3">
          <div class="form-floating flex-grow-1">
            <input
              id="cordHeight"
              :value="config.CordHeight"
              type="number"
              :min="session.minCordOperatingHeight"
              :step="1"
              class="form-control"
              inputmode="decimal"
              pattern="[0-9]*"
              @blur="e => setCordHeight(+e.target.value)"
              @focus="e => e.target.select()"
            >
            <label for="cordHeight">
              {{ $t('article.cordOptions.height') }}
            </label>
          </div>

          <button
            class="btn btn-outline-secondary"
            type="button"
            :disabled="config.CordHeight <= 1"
            @click="setCordHeight(config.CordHeight - 1)"
          >
            -
          </button>
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="setCordHeight(config.CordHeight + 1)"
          >
            +
          </button>
        </div>

        <h6>{{ $t('article.cordOptions.curtainSplit') }}</h6>

        <div class="d-flex flex-wrap">
          <div
            v-for="({ key, label }, i) in splitOptions"
            :key="i"
            class="form-check me-2"
          >
            <input
              :id="`split_${i}`"
              class="form-check-input"
              type="radio"
              :value="key"
              name="split"
              :checked="key === currentSplit"
              @change="setSplit(key)"
            >
            <label
              class="form-check-label"
              :for="`split_${i}`"
            >
              {{ label }}
            </label>
          </div>
        </div>

        <proxy-image
          v-if="cordImage"
          :src="cordImage"
          class="img-fluid mt-2"
        />
      </template>
    </showcase-card>
  </div>
</template>

<script>
import ProxyImage from '@/components/ProxyImage'
import ShowcaseCard from '@/components/ShowcaseCard'

export default {
  name: 'cord-options',

  components: {
    ProxyImage,
    ShowcaseCard,
  },

  computed: {
    // current article-configuration
    config () {
      return this.$store.state.config
    },

    // currently chosen split
    currentSplit () {
      const selected = this.splitOptions.find(({ key }) => key && this.config[key])
      return selected ? selected.key : null
    },

    // preview-image related to the current split/choice
    cordImage () {
      const split = this.splitOptions.find(({ key }) => key === this.currentSplit)

      return split
        ? `/images/Schnurzug/${split.imageName}.svg`
        : null
    },

    session () {
      return this.$store.state.user
    },

    // possible splits
    splitOptions () {
      return [
        { key: 'CordTL', label: this.$t('article.cordOptions.splitOptions.cordTL'), imageName: 'vorhangteilung_links' },
        { key: 'CordTM', label: this.$t('article.cordOptions.splitOptions.cordTM'), imageName: 'vorhangteilung_mittig' },
        { key: 'CordTR', label: this.$t('article.cordOptions.splitOptions.cordTR'), imageName: 'vorhangteilung_rechts' },
      ]
    },
  },

  methods: {
    setCordHeight (height) {
      const min = this.session.minCordOperatingHeight
      this.$emit('input', { key: 'CordHeight', value: height >= min ? height : min })
    },

    setSplit (key) {
      this.$emit('input', { key, value: true })
    },
  },
}
</script>

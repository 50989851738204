<template>
  <a
    ref="infoPopover"
    tabindex="0"
    role="button"
    class="d-inline-flex flex-column text-decoration-none"
  >
    <slot />
  </a>
</template>

<script>
import { Popover } from 'bootstrap'

export default {
  name: 'bt-popover',

  props: {
    options: {
      type: Object,
      default: () => ({})
    },
  },

  mounted () {
    this.initPopover()
  },

  methods: {
    initPopover () {
      // eslint-disable-next-line no-new
      new Popover(this.$refs.infoPopover, {
        placement: 'left',
        trigger: 'focus',
        ...this.options,
      })
    }
  }
}
</script>

<template>
  <div class="catalog-info-alert--wrap">
    <div
      class="alert alert-primary d-flex align-items-center justify-content-center py-2 mb-0 bg-transparent"
      :class="{ 'dense px-2': dense }"
      role="alert"
    >
      {{ $t('infos.currentCatalog') }}

      <bt-popover
        v-if="withPriceInfo"
        :options="{ content: $t('infos.surcharge') }"
        class="ms-2"
      >
        <span class="material-icons text-primary">
          info
        </span>
      </bt-popover>
    </div>
  </div>
</template>

<script>
import BtPopover from '@/components/BtPopover'

export default {
  name: 'catalog-info-alert',

  components: {
    BtPopover,
  },

  props: {
    dense: {
      type: Boolean,
      default: false,
    },

    withPriceInfo: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  .catalog-info-alert--wrap {
    .alert {
      &.dense {
        font-size: 0.85rem;
      }
    }
  }
</style>

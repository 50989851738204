<template>
  <div
    ref="modal"
    class="bt-modal--wrap modal fade"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="$emit('close')"
          />
        </div>

        <div
          :class="`modal-body ${bodyClass}`"
        >
          <slot />
        </div>

        <div
          v-if="$slots.actions"
          class="modal-footer"
        >
          <slot name="actions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
  name: 'bt-modal',

  props: {
    bodyClass: {
      type: String,
      default: '',
    },

    isVisible: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      modal: null,
    }
  },

  watch: {
    isVisible () {
      this.isVisible ? this.modal.show() : this.modal.hide()
    },
  },

  mounted () {
    this.initModal()
  },

  beforeDestroy () {
    this.$refs.modal.remove()
  },

  methods: {
    initModal () {
      const elem = this.$refs.modal
      this.modal = new Modal(elem)

      // move so the modal is above the backdrop even when used in deeper views
      document.body.appendChild(elem)
      // communicate close so using components recognize backdrop-clicks, too
      this.$refs.modal.addEventListener('hidden.bs.modal', () => this.$emit('close'))
    },
  }
}
</script>

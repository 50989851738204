<template>
  <div class="chosen-panel--wrap">
    <h6 class="fw-bold">
      {{ panel.ArticleName }}
    </h6>

    <div class="row gx-2 mb-2">
      <div class="col-6">
        <div v-if="panel.ArticleNr">
          {{ $t('article.panelCarriage.articleNr') }}: {{ panel.ArticleNr }}
        </div>

        <template v-for="color in panel.Colors">
          <div :key="`panelColor_${panel.ArticleNr}_${color.Key}`">
            {{ $t('article.panelCarriage.color') }}: {{ color.Key }} ({{ color.Value }})
          </div>
        </template>

        <div v-if="panel.Length">
          {{ $t('article.panelCarriage.length') }}: {{ panel.Length }} {{ units.measureUnit }}
        </div>
      </div>

      <div class="col-6">
        <proxy-image
          :src="`/images/${panel.Picture}`"
          class="img-fluid mt-2"
        />
      </div>
    </div>

    <div class="input-group flex-nowrap">
      <div class="form-floating flex-grow-1">
        <input
          :id="`chosenPanelAmount_${panel.ArticleNr}`"
          :value="panel.Count"
          type="number"
          :min="0"
          :step="1"
          class="form-control"
          inputmode="decimal"
          pattern="[0-9]*"
          @blur="e => setAmount(+e.target.value)"
          @focus="e => e.target.select()"
        >
        <label :for="`chosenPanelAmount_${panel.ArticleNr}`">
          {{ $t('article.panelCarriage.amount') }}
        </label>
      </div>

      <button
        class="btn btn-outline-secondary px-3"
        type="button"
        :disabled="panel.Count === 0"
        @click="setAmount(panel.Count - 1)"
      >
        -
      </button>
      <button
        class="btn btn-outline-secondary px-3"
        type="button"
        @click="setAmount(panel.Count + 1)"
      >
        +
      </button>
      <button
        class="btn btn-outline-danger d-flex align-items-center"
        type="button"
        @click="removePanel"
      >
        <span class="material-icons">
          remove_circle
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import Api from '@/api'
import ProxyImage from '@/components/ProxyImage'

export default {
  name: 'chosen-panel',

  components: {
    ProxyImage,
  },

  props: {
    panel: {
      type: Object,
      default: null,
      required: true,
    },
  },

  computed: {
    // units to use
    units () {
      return this.$store.state.units
    },
  },

  methods: {
    /**
     * Removes the panel from the current configuration.
     *
     * @returns {Promise}
     */
    async removePanel () {
      const res = await Api.removePanelCarriage(this.panel.Guid)
      res.ok && this.$store.commit('setConfig', await res.json())
    },

    /**
     * Updates the amount of the panel.
     *
     * @param {number} amount
     * @returns {Promise}
     */
    async setAmount (amount) {
      const res = await Api.setPanelCarriageAmount(this.panel.Guid, amount >= 0 ? amount : 0)
      res.ok && this.$store.commit('setConfig', await res.json())
    },
  }
}
</script>

<template>
  <div
    class="bt-accordion--wrap accordion-item"
    :class="{ expanded }"
  >
    <h2
      class="accordion-header"
      @click="$emit('click:header')"
    >
      <button
        class="accordion-button collapsed py-2"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="`#${id}`"
        aria-expanded="false"
      >
        <slot name="prepend-header" />
        {{ title }}
      </button>
    </h2>

    <div
      :id="id"
      ref="collapse"
      class="accordion-collapse collapse"
      :data-bs-parent="parent"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'bt-accordion',

  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },

    parent: {
      type: String,
      default: '',
      required: false,
    },

    title: {
      type: String,
      default: '',
      required: true,
    },
  },

  data () {
    return {
      expanded: false,
    }
  },

  mounted () {
    this.registerListeners()
  },

  methods: {
    /**
     * Adds listeners to custom-events of bootstrap to be able to add stylings,
     * custom behavior to parent elements/components.
     * "stopPropagation" is important since this component can get nested and
     * would react to child-events otherwise.
     *
     * @returns {void}
     */
    registerListeners () {
      const el = this.$refs.collapse

      el.addEventListener('show.bs.collapse', e => {
        e.stopPropagation()
        this.expanded = true
      })

      el.addEventListener('hide.bs.collapse', e => {
        e.stopPropagation()
        this.expanded = false
      })

      el.addEventListener('shown.bs.collapse', e => {
        e.stopPropagation()
        this.$emit('shown')
      })
    },
  }
}
</script>

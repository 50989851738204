export default {
  computed: {
    /**
     * Routes related to the article-views (except the wrapping one).
     *
     * @returns {array}
     */
    articleRoutes () {
      const articleRoute = this.$router.options.routes.find(({ name }) => name === 'Article')
      return articleRoute?.children || []
    },

    /**
     * Article-routes should have a defined order/position (to be able to use
     * appropriate transitions, tell the directions). Returns the one of the
     * current route.
     *
     * @returns {number|undefined}
     */
    currentStep () {
      return this.$route.meta?.order
    },

    /**
     * Route of the view before the current article-step. If there's none, the
     * article-overview is the desired target.
     *
     * @returns {object|undefined}
     */
    prevRoute () {
      return this.currentStep === 0
        ? this.$router.options.routes.find(({ name }) => name === 'Categories')
        : this.articleRoutes.find(({ meta }) => meta.order === this.currentStep - 1)
    },

    /**
     * Route of the view following the current article-step.
     *
     * @returns {object|undefined}
     */
    nextRoute () {
      return this.articleRoutes.find(({ meta }) => meta.order === this.currentStep + 1)
    },
  },
}

import store from '@/store'

export const priceWithSurcharge = price => {
  const priceAsNumber = parseFloat(price.replace(',', '.'))
  const surcharge = store.state?.user?.surcharge && new Date(store.state.user.surcharge.start) < new Date()
    ? store.state.user.surcharge.percentage
    : 0

  return (priceAsNumber * (1 + surcharge / 100))
    .toFixed(2)
    .replace('.', ',')
}

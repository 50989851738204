<template>
  <div
    v-if="catalogEntries.length"
    class="category-view--wrap container-fluid py-2"
  >
    <div class="d-flex align-items-center mb-3">
      <h3 class="m-0">
        {{ $t('categories.headline') }}
      </h3>

      <catalog-info-alert
        class="ms-auto"
        with-price-info
        dense
      />
    </div>

    <div
      id="categoryAccordion"
      class="accordion accordion-flush"
    >
      <catalog-entry
        v-for="entry in catalogEntries"
        :key="entry.Id"
        :entry="entry"
      />
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button
        type="button"
        class="btn btn-dark"
        @click="$store.commit('setFavoritesVisible', true)"
      >
        {{ $t('favorites.title') }}
      </button>
    </div>

    <div class="d-flex flex-wrap justify-content-center my-2">
      <router-link
        :to="{ name: 'Agb' }"
        class="m-2 text-decoration-none"
      >
        {{ $t('infoPages.agb') }}
      </router-link>

      <router-link
        :to="{ name: 'Privacy' }"
        class="m-2 text-decoration-none"
      >
        {{ $t('infoPages.privacy') }}
      </router-link>

      <router-link
        :to="{ name: 'Imprint' }"
        class="m-2 text-decoration-none"
      >
        {{ $t('infoPages.imprint') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import CatalogEntry from '@/components/CatalogEntry'
import CatalogInfoAlert from '@/components/CatalogInfoAlert'

import { Collapse } from 'bootstrap'

export default {
  name: 'category-view',

  components: {
    CatalogEntry,
    CatalogInfoAlert,
  },

  computed: {
    activeDiameter () {
      return this.$store.state.activeDiameter
    },

    activeGroup () {
      return this.$store.state.activeGroup
    },

    /**
     * We want to visualize the catalog-groups like the FHP where's a special-
     * case for entries with the type "Wellenvorhang": Instead of the leading
     * name an icon is shown.
     *
     * @returns {array}
     */
    catalogEntries () {
      const entries = this.$store.state.catalogEntries
      const wellenvorhangRegex = /^Wellenvorhang/i

      return entries.map(entry => ({
        ...entry,
        isWellenvorhang: wellenvorhangRegex.test(entry.Name),
        Name: entry.Name.replace(wellenvorhangRegex, ''),
      }))
    },
  },

  async mounted () {
    this.catalogEntries.length === 0 && await this.$store.dispatch('getCatalog')
    this.openPanels()
  },

  methods: {
    /**
     * If the user navigates back to this view we want to open previously shown
     * panels again.
     *
     * @returns {void}
     */
    openPanels () {
      if (!this.activeGroup) {
        return
      }

      const group = document.getElementById(`entry_${this.activeGroup}`)
      Collapse.getOrCreateInstance(group).show()

      if (this.activeDiameter) {
        group.addEventListener('shown.bs.collapse', () => {
          const diameter = document.getElementById(`${this.activeDiameter}`)
          Collapse.getOrCreateInstance(diameter).show()
        })
      }
    },
  },
}
</script>

<template>
  <div
    v-if="config.FinialList.length > 0 && config.SocketDirection !== 'Both'"
    class="end-pieces--wrap"
  >
    <showcase-card>
      <template #title>
        {{ $t('article.endPieces.title') }}
      </template>

      <template #title-append>
        <button
          class="btn btn-sm p-0 d-flex align-items-center"
          @click="showDialog = true"
        >
          {{ $t('article.endPieces.edit') }}

          <span class="material-icons ms-2">
            settings
          </span>
        </button>
      </template>

      <template #content>
        <div
          v-if="selectedEndPiece"
          class="row"
        >
          <div class="col-6">
            {{ $t('article.endPieces.choice') }} <br>
            {{ selectedEndPiece.Name }} <br>
            {{ $t(`article.endPieces.directions.${config.FinialDirection}`) }}
          </div>
          <div class="col-6">
            <proxy-image
              :src="`/images/${selectedEndPiece.Picture}`"
              class="img-fluid"
            />
          </div>
        </div>
      </template>
    </showcase-card>

    <bt-modal
      :is-visible="showDialog"
      class="end-pieces--dialog"
      body-class="p-0"
      :title="$t('article.endPieces.title')"
      @close="showDialog = false"
    >
      <div class="directions d-flex justify-content-between">
        <button
          v-for="({ disabled, label, name }, i) in directions"
          :key="i"
          class="btn btn-sm py-2"
          :class="{ 'btn-dark': direction === name }"
          :disabled="disabled"
          @click="direction = name"
        >
          {{ label }}
        </button>
      </div>

      <div
        v-if="direction !== 'Without'"
        class="px-3"
      >
        <div
          v-for="(endPiece, i) in config.FinialList"
          :key="endPiece.Id"
          class="row py-3"
          :class="{
            'bg-light text-primary': selection && selection.Id === endPiece.Id,
            'border-bottom': i < config.FinialList.length - 1,
          }"
          @click="selection = endPiece"
        >
          <div class="col-4 d-flex align-items-center">
            <proxy-image
              :src="`/images/${endPiece.Picture}`"
              class="img-fluid"
            />
          </div>
          <div class="col-8">
            {{ endPiece.Name }} <br>
            {{ endPiece.ColorName }} ({{ endPiece.Color }}) <br>
            {{ endPiece.Price | priceWithSurcharge }}&nbsp;€
          </div>
        </div>
      </div>

      <template #actions>
        <button
          class="btn btn-dark"
          @click="chooseEndPiece"
        >
          {{ $t('article.endPieces.choose') }}
        </button>
      </template>
    </bt-modal>
  </div>
</template>

<script>
import BtModal from '@/components/BtModal'
import ProxyImage from '@/components/ProxyImage'
import ShowcaseCard from '@/components/ShowcaseCard'

export default {
  name: 'end-pieces',

  components: {
    BtModal,
    ProxyImage,
    ShowcaseCard,
  },

  data () {
    return {
      direction: null,
      selection: null,
      showDialog: false,
    }
  },

  computed: {
    config () {
      return this.$store.state.config
    },

    directions () {
      const socket = this.config.SocketDirection

      return [{
        name: 'Both',
        label: this.$t('article.endPieces.directions.Both'),
        disabled: socket !== 'Without',
      }, {
        name: 'Left',
        label: this.$t('article.endPieces.directions.Left'),
        disabled: socket === 'Left',
      }, {
        name: 'Right',
        label: this.$t('article.endPieces.directions.Right'),
        disabled: socket === 'Right',
      }, {
        name: 'Without',
        label: this.$t('article.endPieces.directions.Without'),
        disabled: false,
      }]
    },

    selectedEndPiece () {
      return this.config.FinialList.find(({ Selected }) => Selected)
    },
  },

  watch: {
    config: {
      deep: true,
      handler () {
        this.useConfigValues()
      }
    }
  },

  mounted () {
    this.useConfigValues()
  },

  methods: {
    chooseEndPiece () {
      this.showDialog = false
      this.$emit('input', { id: this.selection.Id, direction: this.direction })
    },

    /**
     * Initially (and when the configuration gets updates) we want to select
     * the direction, entry based on that.
     *
     * @returns {void}
     */
    useConfigValues () {
      this.direction = this.config.FinialDirection
      this.selection = this.selectedEndPiece
    }
  }
}
</script>

<style lang="scss">
  .end-pieces--dialog {
    .directions {
      position: sticky;
      top: 0;
      background-color: $gray-300;

      .btn-sm {
        border-radius: 0;
      }
    }
  }
</style>

<template>
  <span class="highlighted-text">
    <template v-for="({ part, highlight }, i) in highlights">
      <span
        :key="i"
        :class="{ highlight }"
      >{{ part }}</span>
    </template>
  </span>
</template>

<script>
export default {
  name: 'text-highlight',

  props: {
    text: {
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
  },

  computed: {
    /**
     * Splits the given text based on the search so we can add highlights in
     * the template (no filter with markup-additions since we don't want to use
     * v-html).
     *
     * @returns {array}
     */
    highlights () {
      return this.text
        .split(new RegExp(`(${this.search})`, 'ig'))
        .filter(x => x)
        .map(part => ({ part, highlight: part.toLowerCase() === this.search.toLowerCase() }))
    }
  },
}
</script>

<style lang="scss">
  .highlighted-text {
    .highlight {
      background-color: $gray-300;
      border-radius: 2px;
    }
  }
</style>

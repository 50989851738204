<template>
  <div id="app">
    <navbar-top />
    <fullscreen-loader />

    <main
      v-if="sessionChecked"
      class="d-flex flex-column"
    >
      <router-view />
    </main>
  </div>
</template>

<script>
import NavbarTop from '@/components/NavbarTop'
import FullscreenLoader from '@/components/FullscreenLoader'

export default {
  components: {
    FullscreenLoader,
    NavbarTop,
  },

  data () {
    return {
      sessionChecked: false,
    }
  },

  computed: {
    user () {
      return this.$store.state.user
    },
  },

  watch: {
    /**
     * Loads list of articles when the user is logged in since we need those in
     * multiple views.
     *
     * @param {object|null} to
     * @param {object|null} from
     */
    user (to, from) {
      to && from === null && this.$store.dispatch('loadArticles')
    },
  },

  mounted () {
    this.checkSession()
  },

  methods: {
    async checkSession () {
      await this.$store.dispatch('getSession')
      this.sessionChecked = true
    }
  },
}
</script>

<style lang="scss">
  @import './scss/styles';

  #app {
    main {
      position: fixed;
      top: $navHeight;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
    }
  }
</style>

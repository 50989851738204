<template>
  <div
    v-if="config.IntegralEnabled"
    class="integral-choice--wrap"
  >
    <showcase-card class="mt-3">
      <template #title>
        {{ $t('article.integral.title') }}
      </template>

      <template #title-append>
        <bt-popover
          :options="{
            title: $t('article.integral.title'),
            content: $t('article.integral.description'),
          }"
          class="ms-auto"
        >
          <span class="material-icons text-dark">
            info
          </span>
        </bt-popover>
      </template>

      <template #content>
        <div class="d-flex flex-wrap">
          <div
            v-for="({ label, value }, i) in options"
            :key="i"
            class="form-check me-4"
          >
            <input
              :id="`integral_option_${i}`"
              class="form-check-input"
              type="radio"
              :checked="value === config.IntegralSelected"
              :value="value"
              @change="e => $emit('input', { key: 'IntegralSelected', value: e.target.value })"
            >
            <label
              class="form-check-label"
              :for="`integral_option_${i}`"
            >
              {{ label }}
            </label>
          </div>
        </div>
      </template>
    </showcase-card>
  </div>
</template>

<script>
import BtPopover from '@/components/BtPopover'
import ShowcaseCard from '@/components/ShowcaseCard'

export default {
  name: 'integral-choice',

  components: {
    BtPopover,
    ShowcaseCard,
  },

  data () {
    return {
      options: [
        { label: this.$t('article.integral.without'), value: false },
        { label: this.$t('article.integral.with'), value: true },
      ],
    }
  },

  computed: {
    // current article-configuration
    config () {
      return this.$store.state.config
    },
  },
}
</script>

<template>
  <div
    v-if="panel"
    class="panel-config--wrap"
  >
    <div class="row gx-1 mb-1">
      <div class="col-6">
        <div class="input-group flex-nowrap">
          <div class="form-floating flex-grow-1">
            <input
              id="panelLength"
              v-model.number="panelConfig.panelLength"
              type="number"
              :min="minLength"
              :max="maxLength"
              :step="1"
              class="form-control"
              inputmode="decimal"
              pattern="[0-9]*"
              @blur="restrictLength"
              @focus="e => e.target.select()"
            >
            <label for="panelLength">
              {{ $t('article.panelCarriage.length') }}
            </label>
          </div>

          <span class="input-group-text">{{ units.measureUnit }}</span>
        </div>
      </div>

      <div class="col-6">
        <div class="input-group flex-nowrap">
          <div class="form-floating flex-grow-1">
            <input
              :id="`panelAmount_${panel.ArticleNr}`"
              v-model.number="panelConfig.amount"
              type="number"
              :min="minAmount"
              :step="1"
              class="form-control"
              inputmode="decimal"
              pattern="[0-9]*"
              @blur="restrictAmount"
              @focus="e => e.target.select()"
            >
            <label :for="`panelAmount_${panel.ArticleNr}`">
              {{ $t('article.panelCarriage.amount') }}
            </label>
          </div>

          <button
            class="btn btn-outline-secondary"
            type="button"
            :disabled="panelConfig.amount <= minAmount"
            @click="panelConfig.amount--"
          >
            -
          </button>
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="panelConfig.amount++"
          >
            +
          </button>
        </div>
      </div>
    </div>

    <div class="form-floating mb-2">
      <select
        id="panelColor"
        v-model="panelConfig.color"
        class="form-select"
      >
        <option
          v-for="color in panel.Colors"
          :key="`${panel.ArticleNr}_${color.Key}`"
          :value="color.Key"
        >
          {{ color.Value }}
        </option>
      </select>
      <label for="panelColor">
        {{ $t('article.panelCarriage.color') }}
      </label>
    </div>

    <div class="d-flex">
      <button
        class="btn btn-sm ms-auto d-flex align-items-center"
        @click="add"
      >
        {{ $t('article.panelCarriage.add') }}

        <span class="material-icons ms-2">
          add_circle
        </span>
      </button>
    </div>

    <bt-toast ref="toast" />
  </div>
</template>

<script>
import Api from '@/api'
import BtToast from '@/components/BtToast'

const minAmount = 1
const minLength = 20
const maxLength = 140

export default {
  name: 'panel-config',

  components: {
    BtToast,
  },

  props: {
    panel: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      minAmount,
      minLength,
      maxLength,

      panelConfig: {
        amount: minAmount,
        color: null,
        panelLength: minLength,
      },
    }
  },

  computed: {
    // units to use
    units () {
      return this.$store.state.units
    },
  },

  watch: {
    panel () {
      this.panel && (this.panelConfig.color = this.panel.DefaultColor)
    },
  },

  methods: {
    /**
     * Adds the panel with the current options to the article-configuration.
     *
     * @returns {Promise}
     */
    async add () {
      const res = await Api.addPanelCarriage(this.panel.ArticleNr, this.panelConfig)

      if (res.ok) {
        this.$store.commit('setConfig', await res.json())
        this.$refs.toast.show(
          this.$t('article.panelCarriage.added'),
          this.$t('article.panelCarriage.addedInfo'),
        )
      }
    },

    /**
     * Ensures that the entered amount is within the allowed range.
     *
     * @returns {void}
     */
    restrictAmount () {
      this.panelConfig.amount < this.minAmount && (this.panelConfig.amount = minAmount)
    },

    /**
     * Ensures that the entered length is within the allowed range.
     *
     * @returns {void}
     */
    restrictLength () {
      this.panelConfig.panelLength < minLength && (this.panelConfig.panelLength = minLength)
      this.panelConfig.panelLength > maxLength && (this.panelConfig.panelLength = maxLength)
    }
  }
}
</script>

<template>
  <div
    v-if="isLoading"
    class="fullscreen-loader--wrap d-flex flex-column align-items-center justify-content-center"
  >
    <proxy-image
      src="/gfx/interstilLogoInv.svg"
      draggable="false"
      class="logo img-responsive mb-3"
    />

    <div class="info">
      {{ $t('loadData') }}
    </div>
  </div>
</template>

<script>
import ProxyImage from '@/components/ProxyImage'

export default {
  name: 'fullscreen-loader',

  components: {
    ProxyImage,
  },

  computed: {
    isLoading () {
      return this.$store.state.isLoading
    },
  },
}
</script>

<style lang="scss">
  .fullscreen-loader--wrap {
    background-color: #202020;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1031; // one above fixed navs

    .logo {
      max-width: 50%;
    }

    .info {
      color: #fff;
      font-size: 1.25rem;
      animation: pulse 2s infinite ease-in-out;
    }

    @keyframes pulse {
      0% { opacity: 0.5; }
      50% { opacity: 1; }
      100% { opacity: 0.5; }
    }
  }
</style>>

<template>
  <div class="info-index--wrap container-fluid py-3">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'info-index',
}
</script>

<style lang="scss">
  .info-index--wrap {
    a {
      overflow-wrap: break-word;
    }
  }
</style>

<template>
  <div class="activate-view--wrap container-fluid py-3">
    <h1 class="mb-4">
      {{ $t('activation.title') }}
    </h1>

    <form @submit.prevent="activate">
      <div class="form-floating mb-2">
        <input
          id="password"
          v-model="password"
          type="password"
          class="form-control"
          required
          autocomplete="new-password"
        >
        <label for="password">{{ $t('activation.password') }}</label>
      </div>
      <div class="form-floating mb-2">
        <input
          id="passwordConfirmation"
          v-model="passwordConfirmation"
          type="password"
          class="form-control"
          required
          autocomplete="new-password"
        >
        <label for="passwordConfirmation">{{ $t('activation.confirmation') }}</label>
      </div>

      <div class="d-flex">
        <button
          type="submit"
          class="btn btn-success d-flex align-center ms-auto"
        >
          <i class="material-icons me-2">person_add</i>
          <span>{{ $t('activation.activate') }}</span>
        </button>
      </div>
    </form>

    <bt-toast ref="toast" />
  </div>
</template>

<script>
import Api from '@/api'
import BtToast from '@/components/BtToast'

export default {
  name: 'activate-view',

  components: {
    BtToast,
  },

  data () {
    return {
      password: '',
      passwordConfirmation: '',
    }
  },

  computed: {
    id () {
      return this.$route.params.id
    }
  },

  methods: {
    /**
     * Tries to activate the user with the registration-id given as a url-param
     *
     * @returns {Promise}
     */
    async activate () {
      if (!this.password || !this.passwordConfirmation || this.password !== this.passwordConfirmation) {
        this.$refs.toast.show(this.$t('activation.error'), this.$t('activation.passwordError'))
        return
      }

      const res = await Api.activate(this.id, this.password)

      if (res.ok) {
        this.$router.push({ name: 'Login' })
        return
      }

      const error = await res.json()
      this.$refs.toast.show(this.$t('activation.error'), error.err)
    },
  },
}
</script>

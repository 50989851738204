<template>
  <div class="register-view--wrap container-fluid py-3">
    <h1 class="mb-4">
      {{ $t('register.title') }}
    </h1>

    <div
      class="alert alert-primary mb-4"
      role="alert"
    >
      {{ $t('register.info') }}
    </div>

    <form @submit.prevent="register">
      <div class="row gx-1">
        <div class="col-6">
          <div class="form-floating mb-2">
            <input
              id="firstname"
              v-model="user.firstname"
              type="text"
              class="form-control"
              required
            >
            <label for="firstname">{{ $t('register.firstname') }}</label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-floating mb-2">
            <input
              id="lastname"
              v-model="user.lastname"
              type="text"
              class="form-control"
              required
            >
            <label for="lastname">{{ $t('register.lastname') }}</label>
          </div>
        </div>
      </div>

      <div class="row gx-1">
        <div class="col-6">
          <div class="form-floating mb-2">
            <input
              id="customerId"
              v-model="user.customerId"
              type="text"
              class="form-control"
              required
            >
            <label for="customerId">{{ $t('register.customerId') }}</label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-floating mb-2">
            <input
              id="zip"
              v-model="user.zip"
              type="number"
              class="form-control"
              required
            >
            <label for="zip">{{ $t('register.zip') }}</label>
          </div>
        </div>
      </div>

      <div class="form-floating mb-2">
        <input
          id="company"
          v-model="user.company"
          type="text"
          class="form-control"
          required
        >
        <label for="company">{{ $t('register.company') }}</label>
      </div>

      <div class="form-floating mb-2">
        <input
          id="email"
          v-model="user.email"
          type="email"
          class="form-control"
          required
        >
        <label for="email">{{ $t('register.email') }}</label>
      </div>

      <div class="form-floating mb-4">
        <input
          id="emailConfirm"
          v-model="user.emailConfirm"
          type="email"
          class="form-control"
          required
        >
        <label for="emailConfirm">{{ $t('register.emailConfirm') }}</label>
      </div>

      <vue-recaptcha
        v-if="captchaKey"
        ref="recaptcha"
        :sitekey="captchaKey"
        load-recaptcha-script
        class="mb-4"
        @verify="response => user.recaptchaResponse = response"
      />

      <div class="d-flex">
        <button
          type="submit"
          class="btn btn-success d-flex align-center ms-auto"
          :disabled="!user.recaptchaResponse"
        >
          <i class="material-icons me-2">person_add</i>
          <span>{{ $t('register.register') }}</span>
        </button>
      </div>
    </form>

    <bt-toast ref="toast" />
  </div>
</template>

<script>
import Api from '@/api'
import BtToast from '@/components/BtToast'

import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'register-view',

  components: {
    BtToast,
    VueRecaptcha,
  },

  data () {
    return {
      captchaKey: '',
      user: {
        firstname: '',
        lastname: '',
        customerId: '',
        company: '',
        zip: '',
        email: '',
        emailConfirm: '',
        recaptchaResponse: '',
      },
    }
  },

  mounted () {
    this.getCaptchaKey()
  },

  methods: {
    /**
     * Loads the public captcha-key.
     *
     * @returns {Promise}
     */
    async getCaptchaKey () {
      const res = await Api.recaptchaKey()

      if (res.ok) {
        const { key } = await res.json()
        this.captchaKey = key
      }
    },

    /**
     * Tries to register a new user, shows an information based on the response
     * from the api.
     *
     * @returns {Promise}
     */
    async register () {
      const res = await Api.register(this.user)

      if (res.ok) {
        this.$refs.toast.show(this.$t('register.success'), this.$t('register.successHint'))
        return
      }

      const error = await res.json()
      this.$refs.toast.show(this.$t('register.error'), error.err)
    },
  },
}
</script>

<template>
  <div
    :key="`bracket_${config.BracketSelected}`"
    class="bracket-choice--wrap"
  >
    <div class="input-group flex-nowrap">
      <div class="form-floating flex-grow-1">
        <select
          id="bracketChoice"
          :value="config.BracketSelected"
          class="form-select"
          @input="e => $emit('input', e.target.value)"
        >
          <option
            v-for="(bracket, i) in config.BracketList"
            :key="i"
            :value="bracket"
            :selected="bracket === config.BracketSelected"
          >
            {{ bracket }}
          </option>
        </select>

        <label
          for="bracketChoice"
          class="mb-1"
        >
          {{ $t('article.bracket') }}
        </label>
      </div>
      <span class="input-group-text">{{ units.measureUnit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bracket-choice',

  computed: {
    // current article-configuration
    config () {
      return this.$store.state.config
    },

    // units to use
    units () {
      return this.$store.state.units
    },
  },
}
</script>

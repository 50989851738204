<template>
  <div class="imprint-view--wrap">
    <h4>
      Verantwortlich für den Inhalt:
    </h4>
    <div>
      interstil
    </div>
    <div>
      Diedrichsen GmbH &amp; Co. KG
    </div>
    <div>
      Liebigstraße 1-3
    </div>
    <div>
      D-33803 Steinhagen
    </div>
    <div>
      Telefon +49 (0)5204 91 36-0
    </div>
    <div>
      Telefax +49 (0)5204 91 36-10
    </div>
    <div>
      <a
        href="mailto:info@interstil.de"
        title="E-Mail an interstil"
      >info(at)interstil.de</a>
    </div>
    <div>
      <a
        href="http://www.interstil.de/"
        title="www.interstil.de"
        target="_blank"
      >
        www.interstil.de
      </a>
    </div><br> <h4>
      Geschäftsführer:
    </h4> <div>
      Manfred Diedrichsen | Dr. Jens Diedrichsen
    </div><br> <div>
      Amtsgericht: Gütersloh
    </div>
    <div>
      HRA: 4523
    </div>
    <div>
      USt.-ID: DE 126951 371
    </div><br> <h4>
      Persönlich haftende Gesellschafterin:
    </h4> <div>
      interstil Diedrichsen Verwaltungs-GmbH
    </div><br> <div>
      Amtsgericht: Gütersloh
    </div>
    <div>
      HRB: 5521
    </div>
  </div>
</template>

<script>
export default {
  name: 'imprint-view',
}
</script>

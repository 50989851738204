<template>
  <div class="favorite-dialog--wrap">
    <bt-modal
      :is-visible="dialogVisible"
      :title="$t('favorites.title')"
      class="favorite-dialog"
      @close="$store.commit('setFavoritesVisible', false)"
    >
      <div v-if="favorites.length === 0">
        {{ $t('favorites.missing') }}
      </div>

      <template v-else>
        <div
          v-for="({ articleNumber, color, image, articleName, _id }, i) in favorites"
          :key="i"
          class="favorite row py-2"
        >
          <div class="col-5">
            <proxy-image
              v-if="image"
              :src="image"
              :alt="articleName"
              class="img-fluid"
              @click="goToArticle(articleNumber, color)"
            />
          </div>
          <div class="col-7 d-flex align-items-center">
            <div @click="goToArticle(articleNumber, color)">
              <div>{{ articleName }}</div>
              <div>#{{ articleNumber }}</div>
            </div>

            <span
              class="material-icons ms-auto"
              @click="deleteFavorite(_id)"
            >
              delete_outline
            </span>

            <span
              class="material-icons ms-2"
              @click="goToArticle(articleNumber, color)"
            >
              chevron_right
            </span>
          </div>
        </div>
      </template>

      <template
        v-if="showAddButton"
        #actions
      >
        <button
          type="button"
          class="btn btn-dark"
          @click="addFavorite()"
        >
          {{ $t('favorites.addCurrent') }}
        </button>
      </template>
    </bt-modal>

    <slot :favorites="favorites" />
  </div>
</template>

<script>
import Api from '@/api'
import BtModal from '@/components/BtModal'
import ProxyImage from '@/components/ProxyImage'

export default {
  name: 'favorite-dialog',

  components: {
    BtModal,
    ProxyImage,
  },

  data () {
    return {
      favorites: [],
    }
  },

  computed: {
    // currently loaded article
    article () {
      return this.$store.state.article
    },

    // currently chosen article-color
    articleColor () {
      return this.$store.state.color
    },

    // dialog should get shown
    dialogVisible () {
      return this.$store.state.favoritesVisible
    },

    // in an article-view we want to add the possibility to add favorites
    showAddButton () {
      if (!this.article || !this.articleColor) {
        return false
      }

      const alreadyAdded = this.favorites.find(({ articleNumber, color }) =>
        color === this.articleColor && articleNumber === this.article.Articlenr
      ) !== undefined

      return !alreadyAdded
    }
  },

  mounted () {
    this.getFavorites()
  },

  methods: {
    /**
     * Adds the currently chosen article-/color-combination as a favorite.
     *
     * @return {Promise}
     */
    async addFavorite () {
      const { Value: image } = this.article.Colors.find(({ Key }) => Key === this.articleColor)

      this.article && this.articleColor && await Api.addFavorite(
        this.article.Name,
        this.article.Articlenr,
        this.articleColor,
        image
      )

      this.getFavorites()
    },

    /**
     * Deletes the favorite with the given id.
     *
     * @param {string} id
     * @return {Promise}
     */
    async deleteFavorite (id) {
      await Api.deleteFavorite(id)
      this.getFavorites()
    },

    /**
     * Loads favorites of the current user.
     *
     * @return {Promise}
     */
    async getFavorites () {
      const favorites = await Api.getFavorites()
      this.favorites = favorites.map(favorite => ({ ...favorite, image: `/images/${favorite.image}` }))
    },

    /**
     * Redirects to the detail-page of an article with the given articlenumber,
     * color (if we're not there already), closes the dialog.
     *
     * @param {string} articleNr
     * @param {string} color
     * @returns {void}
     */
    goToArticle (articleNr, color) {
      const { articleNr: currentArticle, color: currentColor } = this.$route.params

      if (!currentArticle || currentArticle !== articleNr || currentColor !== color) {
        this.$router.replace({ name: 'Article', params: { articleNr, color } })
      }

      this.$store.commit('setFavoritesVisible', false)
    },
  },
}
</script>

<style lang="scss">
  .favorite-dialog {
    .favorite {
      cursor: pointer;
      border-bottom: 1px solid $gray-200;

      &:last-child {
        border-bottom: none;
      }
    }
  }
</style>

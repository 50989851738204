import Vue from 'vue'
import Vuex from 'vuex'

import Api from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeDiameter: null, // last viewed diameter within a category
    activeGroup: null, // last viewed category
    article: null, // currently chosen article
    articles: [], // all available articles
    catalogEntries: [], // loaded catalog/program
    color: '', // currently chosen article-color
    config: null, // current article-configuration
    favoritesVisible: false, // favorite-dialog is visible
    isLoading: false, // shows a fullscreen-overlay
    units: null, // measure-units to use
    user: null, // current user
    userConfigs: [], // saved configurations of current user
  },

  mutations: {
    setActiveGroup (state, group) {
      state.activeGroup = group
    },

    setActiveDiameter (state, diameter) {
      state.activeDiameter = diameter
    },

    setArticle (state, article) {
      state.article = article
    },

    setArticles (state, articles = []) {
      state.articles = articles
    },

    setCatalog (state, entries = []) {
      state.catalogEntries = entries
    },

    setColor (state, color) {
      state.color = color
    },

    setConfig (state, config) {
      state.config = config
    },

    setFavoritesVisible (state, favoritesVisible) {
      state.favoritesVisible = favoritesVisible
    },

    setLoading (state, isLoading = false) {
      state.isLoading = isLoading
    },

    setUnits (state, units) {
      state.units = units
    },

    setUser (state, user) {
      state.user = user
    },

    setUserConfigs (state, configs = []) {
      state.userConfigs = configs
    },
  },

  actions: {
    /**
     * getSession
     *
     * @param {object} context
     * @returns {Promise}
     */
    async getSession ({ commit }) {
      const res = await Api.getSession()

      if (res.ok) {
        const { catalog, user } = await res.json()
        commit('setUnits', catalog)
        commit('setUser', user)
      }
    },

    /**
     * getCatalog
     *
     * @param {object} context
     * @returns {Promise}
     */
    async getCatalog ({ commit }) {
      const res = await Api.getCatalog()

      if (res.ok) {
        const catalog = await res.json()
        commit('setCatalog', catalog.Items)
      }
    },

    /**
     * Loads the full dataset of the article with the given number.
     *
     * @param {object} context
     * @param {string} articleNr
     * @returns {Promise}
     */
    async loadArticleData ({ commit }, articleNr) {
      const article = await Api.getArticle(articleNr)
      commit('setArticle', article)
    },

    /**
     * Loads the list of all available articles.
     *
     * @param {object} context
     * @returns {Promise}
     */
    async loadArticles ({ commit }) {
      commit('setArticles', await Api.getArticles())
    },

    /**
     * Loads the configuration of the currently selected article.
     *
     * @param {object} context
     * @returns {Promise}
     */
    async loadConfig ({ commit, state }) {
      if (state.article?.Articlenr) {
        const config = await Api.getConfig(state.article.Articlenr, state.color)
        config && commit('setConfig', config)
        return config
      }
    },

    /**
     * Loads the configurations saved by the user.
     *
     * @param {object} context
     * @returns {Promise}
     */
    async loadUserConfigs ({ commit }) {
      const configs = await Api.loadUserConfigs()
      commit('setUserConfigs', configs)
    },
  },
})

<template>
  <div class="bt-toast--wrap">
    <div class="toast-container p-3">
      <div
        ref="toast"
        class="toast"
      >
        <div class="toast-header text-white bg-primary">
          <strong>{{ title }}</strong>
          <button
            type="button"
            class="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          />
        </div>
        <div class="toast-body bg-white">
          {{ content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'bootstrap'

export default {
  name: 'bt-toast',

  data () {
    return {
      title: '',
      content: '',
      toast: null,
    }
  },

  mounted () {
    this.toast = new Toast(this.$refs.toast, { delay: 4000 })
  },

  methods: {
    show (title, content) {
      this.title = title
      this.content = content
      this.toast.show()
    },
  },
}
</script>

<style lang="scss">
  .bt-toast--wrap {
    .toast-container {
      position: fixed;
      top: $navHeight;
      right: 0;
      z-index: 4;

      .toast {
        overflow: hidden;
      }

      .toast-body {
        white-space: pre-line;
      }
    }
  }
</style>

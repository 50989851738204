<template>
  <div class="image-choice--wrap">
    <div class="slider--wrap">
      <div
        v-if="isCube"
        ref="slider"
        class="swiper main-swiper"
      >
        <div class="swiper-wrapper">
          <div
            v-for="({ image, label, value }) in colorOptions"
            :key="`slide_${value}`"
            class="swiper-slide"
          >
            <div class="swiper-zoom-container">
              <proxy-image
                :src="image"
                :alt="label"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        ref="slider"
        class="swiper nav-swiper"
      >
        <div
          v-if="color && colorOptions.length > 0"
          class="d-flex justify-content-center"
        >
          <span class="color-label mb-2">
            {{ colorOptions.find(({ value }) => value === color).label }}
          </span>
        </div>

        <div class="swiper-wrapper">
          <div
            v-for="({ image, label, value }) in colorOptions"
            :key="`nav_${value}`"
            class="swiper-slide"
          >
            <proxy-image
              :src="image"
              :alt="label"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProxyImage from '@/components/ProxyImage'

import Swiper, { EffectCube, Zoom } from 'swiper'
import 'swiper/scss'
import 'swiper/scss/effect-cube'

import debounce from '@/utils/debounce'

export default {
  name: 'image-choice',

  components: {
    ProxyImage,
  },

  props: {
    isCube: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      swiper: null,
    }
  },

  computed: {
    activeSlide () {
      return this.colorOptions.findIndex(({ value }) => value === this.color) || 0
    },

    // dataset of the desired article after loading it
    article () {
      return this.$store.state.article
    },

    // picked color-combination
    color () {
      return this.$store.state.color
    },

    // readable names of the colors related to the loaded article
    colorNames () {
      return this.$store.state.article?.ColorNames || []
    },

    // colors of the current article to choose from
    colorOptions () {
      const colors = this.$store.state.article?.Colors || []

      return colors.map(({ Key, Value }) => {
        const colorName = Key.split('/')
          .map(colorId => this.colorNames.find(colorName => colorName.Key === colorId).Value)
          .join('/')

        return {
          value: Key,
          label: `${Key} (${colorName})`,
          image: `/images/${Value}`
        }
      })
    },
  },

  watch: {
    // move to fitting slide when the color gets changed
    color () {
      this.swiper.slideToLoop(this.activeSlide)
    },

    // reinit slider when the article gets changed
    article () {
      this.initSlider()
    }
  },

  mounted () {
    this.initSlider()
  },

  methods: {
    /**
     * We change the route-parameter accordingly so the chosen color gets used
     * even after a reload of the view. Main-article-component deals related
     * stuff (e.g. loading related configuration) based on the route.
     *
     * @param {string} color
     * @returns {void}
     */
    changeColor (color) {
      if (color !== this.color) {
        this.$router.replace({ name: this.$route.name, params: { ...this.$route.params, color } })
      }
    },

    /**
     * Initializes a slider of the chosen type: Either a zoomable cube with a
     * single image or a clickable horizontal one mit multiple images.
     *
     * @returns {void}
     */
    initSlider () {
      const swiperOptions = this.isCube
        ? {
            modules: [
              EffectCube,
              Zoom,
            ],
            zoom: true,
            effect: 'cube',
            cubeEffect: {
              shadow: false,
            },
          }
        : {
            spaceBetween: 8,
            slidesPerView: 3,
            centeredSlides: true,
            slideToClickedSlide: true,
            allowTouchMove: false,
            initialSlide: this.activeSlide,
          }

      this.swiper = new Swiper(this.$refs.slider, {
        ...swiperOptions,
        initialSlide: this.activeSlide,
        on: {
          slideChange: debounce(() => {
            this.changeColor(this.colorOptions[this.swiper.realIndex].value)
          }, 750)
        },
      })
    },
  },
}
</script>

<style lang="scss">
  .image-choice--wrap {
    .slider--wrap {
      .swiper {
        overflow: hidden;

        .swiper-slide {
          position: relative;
        }

        .color-label {
          display: inline-block;
          background-color: $gray-900;
          color: #fff;
          padding: 2px 6px;
          font-size: 0.85rem;
        }

        &.nav-swiper {
          .swiper-slide {
            opacity: 0.4;
            border: 1px solid $gray-900;
            border-radius: 4px;
            overflow: hidden;

            &.swiper-slide-active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>

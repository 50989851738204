<template>
  <div class="reset-config-dialog--wrap">
    <bt-modal
      :title="$t('article.resetDialog.title')"
      :is-visible="value"
      class="reset-config-dialog"
      @close="$emit('input', false)"
    >
      {{ $t('article.resetDialog.info') }}

      <template #actions>
        <button
          class="btn btn-light"
          @click="$emit('input', false)"
        >
          {{ $t('article.resetDialog.cancel') }}
        </button>
        <button
          class="btn btn-dark ms-auto"
          @click="resetArticleConfiguration()"
        >
          {{ $t('article.resetDialog.reset') }}
        </button>
      </template>
    </bt-modal>
  </div>
</template>

<script>
import Api from '@/api'
import BtModal from '@/components/BtModal'

export default {
  name: 'reset-config-dialog',

  components: {
    BtModal,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Resets the current article-configuration.
     *
     * @returns {Promise}
     */
    async resetArticleConfiguration () {
      this.$emit('input', false)

      this.$store.commit('setLoading', true)
      await Api.resetArticleConfiguration()
      await this.$store.dispatch('loadConfig')
      this.$store.commit('setLoading', false)
    },
  },
}
</script>

<style lang="scss">
  .reset-config-dialog {
    .user-config {
      cursor: pointer;
      border-bottom: 1px solid $gray-200;

      &:last-child {
        border-bottom: none;
      }

      .entry-actions {
        min-width: 70px;
        text-align: right;
      }
    }
  }
</style>

<template>
  <div class="image-magnifier--wrap">
    <bt-modal
      :is-visible="magnify"
      :title="title"
      @close="magnify = false"
    >
      <proxy-image
        :src="image"
        class="img-fluid"
      />
    </bt-modal>

    <span
      class="material-icons p-2"
      @click="magnify = true"
    >
      zoom_in
    </span>
  </div>
</template>

<script>
import BtModal from '@/components/BtModal'
import ProxyImage from '@/components/ProxyImage'

export default {
  name: 'image-magnifier',

  components: {
    BtModal,
    ProxyImage,
  },

  props: {
    image: {
      type: String,
      default: null,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      magnify: false,
    }
  },
}
</script>

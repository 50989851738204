<template>
  <div class="agb-view--wrap">
    <div>
      <h3>
        Allgemeine Verkaufs- und Lieferbedingungen
      </h3>

      <h4>
        § 1 Geltungsbereich
      </h4>
      <p>
        (1) Diese Verkaufsbedingungen gelten ausschließlich gegenüber Unternehmern, juristischen Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen im Sinne von § 310 Absatz 1 BGB. Entgegenstehende oder von unseren Verkaufsbedingungen abweichende Bedingungen des Bestellers erkennen wir nur an, wenn wir ausdrücklich schriftlich der Geltung zustimmen.
      </p>
      <p>
        (2) Diese Verkaufsbedingungen gelten auch für alle zukünftigen Geschäfte mit dem Besteller, soweit es sich um Rechtsgeschäfte verwandter Art handelt.
      </p>

      <h4>
        § 2 Angebot und Vertragsabschluss
      </h4>
      <p>
        (1) Sofern eine Bestellung als Angebot gemäß § 145 BGB anzusehen ist, können wir diese innerhalb von zwei Wochen annehmen.
      </p>

      <h4>
        § 3 Überlassene Unterlagen
      </h4>
      <p>
        (1) An allen in Zusammenhang mit der Auftragserteilung dem Besteller überlassenen Unterlagen, wie z. B. Kalkulationen, Zeichnungen etc., behalten wir uns Eigentums- und Urheberrechte vor.  Diese Unterlagen dürfen Dritten nicht zugänglich gemacht werden, es sei denn, wir erteilen dazu dem Besteller unsere ausdrückliche schriftliche Zustimmung. Soweit wir das Angebot des Bestellers nicht innerhalb der Frist von § 2 annehmen, sind diese Unterlagen uns unverzüglich zurückzusenden.
      </p>

      <h4>
        § 4 Preise und Zahlung
      </h4>
      <p>
        (1) Sofern nichts Gegenteiliges schriftlich vereinbart wird, gelten unsere Preise ab Werk ausschließlich Verpackung und zuzüglich Mehrwertsteuer in jeweils gültiger Höhe. Wir behalten uns vor, die Kosten der Verpackung gesondert in Rechnung zu stellen.
      </p>
      <p>
        (2) Die Zahlung des Kaufpreises hat ausschließlich auf das auf der Rechnung angegebene Konto zu erfolgen.
      </p>
      <p>
        (3) Sofern nichts anderes vereinbart wird, ist der Kaufpreis innerhalb von 30 Tagen ohne Abzüge zu zahlen. Der Abzug von Skonto bedarf gesonderter Vereinbarung. Verzugszinsen werden in Höhe von 8 % über dem jeweiligen Basiszinssatz p.a. berechnet. Die Geltendmachung eines höheren Verzugsschadens bleibt vorbehalten.
      </p>
      <p>
        (4) Geschäftsgrundlage sind die in den Katalogen abgedruckten Preise sowie gesondert vereinbarte Rabatte. Wir behalten uns vor, diese Preise und Rabatte aufgrund von veränderten Lohn-, Material- und Vertriebskosten mit einer Ankündigungsfrist von 3 Monaten zu verändern. Dies kann auch ohne die Herausgabe neuer Kataloge erfolgen. An Angebote halten wir uns generell 4 Wochen gebunden.
      </p>

      <h4>
        § 5 Aufrechnung und Zurückbehaltungsrechte
      </h4>
      <p>
        (1) Dem Besteller steht das Recht zur Aufrechnung nur zu, wenn seine Gegenansprüche rechtskräftig festgestellt oder unbestritten sind. Zur Ausübung eines Zurückbehaltungsrechts ist der Besteller nur insoweit befugt, als sein Gegenanspruch auf dem gleichen Vertragsverhältnis beruht.
      </p>

      <h4>
        § 6 Lieferzeit
      </h4>
      <p>
        (1) Der Beginn der von uns angegebenen Lieferzeit setzt die rechtzeitige und ordnungsgemäße Erfüllung der Verpflichtungen des Bestellers sowie die Klärung sämtlicher technischer Fragen voraus. Die Einrede des nicht erfüllten Vertrages bleibt vorbehalten.
      </p>
      <p>
        (2) Kommt der Besteller in Annahmeverzug oder verletzt er schuldhaft sonstige Mitwirkungspflichten, so sind wir berechtigt, den uns insoweit entstehenden Schaden, einschließlich etwaiger Mehraufwendungen ersetzt zu verlangen. Weitergehende Ansprüche bleiben vorbehalten. Sofern vorstehende Voraussetzungen vorliegen, geht die Gefahr eines zufälligen Untergangs oder einer zufälligen Verschlechterung der Kaufsache in dem Zeitpunkt auf den Besteller über, in dem dieser in Annahme- oder Schuldnerverzug geraten ist.
      </p>
      <p>
        (3) Wir haften im Fall des von uns nicht vorsätzlich oder grob fahrlässig herbeigeführten Lieferverzugs für jede vollendete Woche Verzug im Rahmen einer pauschalierten Verzugsentschädigung in Höhe von 0,5 % des Lieferwertes, maximal jedoch nicht mehr als 5 % des Lieferwertes.
      </p>
      <p>
        (4) Weitere gesetzliche Ansprüche und Rechte des Bestellers wegen eines Lieferverzuges bleiben unberührt.
      </p>

      <h4>
        § 7 Gefahrübergang bei Versendung, Verpackung, Rücknahme
      </h4>
      <p>
        (1) Wird die Ware auf Wunsch des Bestellers an diesen versandt, so geht mit der Absendung an den Besteller, spätestens mit Verlassen des Werks/Lagers die Gefahr des zufälligen Untergangs oder der zufälligen Verschlechterung der Ware auf den Besteller über. Dies gilt unabhängig davon, ob die Versendung der Ware vom Erfüllungsort erfolgt oder wer die Frachtkosten trägt.
      </p>
      <p>
        (2) Transport- und alle sonstigen Verpackungen werden nicht zurückgenommen. Davon ausgenommen sind Transportgestelle und Paletten. Der Besteller ist verpflichtet für eine Entsorgung der Verpackungen auf eigene Kosten zu sorgen.
      </p>
      <p>
        (3) Bei maßkonfektionierter Ware sind wir zur Rücknahme nicht verpflichtet. Dies gilt auch, wenn die Lieferung sich noch in ungeöffneter und unbeschädigter Originalverpackung befindet.
      </p>
      <p>
        (4) Wird die Ware trotzdem zurückgenommen, sind wir zu einem Abzug von 30% vom Nettowarenwert berechtigt. Beschädigte Ware wird nicht gutgeschrieben. Die Transportkosten für die Rücksendung trägt der Besteller.
      </p>

      <h4>
        § 8 Eigentumsvorbehalt
      </h4>
      <p>
        (1) Wir behalten uns das Eigentum an der gelieferten Sache bis zur vollständigen Zahlung sämtlicher Forderungen aus dem Liefervertrag vor. Dies gilt auch für alle zukünftigen Lieferungen, auch wenn wir uns nicht stets ausdrücklich hierauf berufen. Wir sind berechtigt, die Kaufsache zurückzunehmen, wenn der Besteller sich vertragswidrig verhält.
      </p>
      <p>
        (2) Der Besteller ist verpflichtet, solange das Eigentum noch nicht auf ihn übergegangen ist, die Kaufsache pfleglich zu behandeln. Insbesondere ist er verpflichtet, diese auf eigene Kosten gegen Diebstahl-, Feuer- und Wasserschäden ausreichend zum Neuwert zu versichern. Müssen Wartungs- und Inspektionsarbeiten durchgeführt werden, hat der Besteller diese auf eigene Kosten rechtzeitig auszuführen. Solange das Eigentum noch nicht übergegangen ist, hat uns der Besteller unverzüglich schriftlich zu benachrichtigen, wenn der gelieferte Gegenstand gepfändet oder sonstigen Eingriffen Dritter ausgesetzt ist. Soweit der Dritte nicht in der Lage ist, uns die gerichtlichen und außergerichtlichen Kosten einer Klage gemäß § 771 ZPO zu erstatten, haftet der Besteller für den uns entstandenen Ausfall.
      </p>
      <p>
        (3) Der Besteller ist zur Weiterveräußerung der Vorbehaltsware im normalen Geschäftsverkehr berechtigt. Die Forderungen des Abnehmers aus der Weiterveräußerung der Vorbehaltsware tritt der Besteller schon jetzt an uns in Höhe des mit uns vereinbarten Faktura-Endbetrages (einschließlich Mehrwertsteuer) ab. Diese Abtretung gilt unabhängig davon, ob die Kaufsache ohne oder nach Verarbeitung weiterverkauft worden ist. Der Besteller bleibt zur Einziehung der Forderung auch nach der Abtretung ermächtigt. Unsere Befugnis, die Forderung selbst einzuziehen, bleibt davon unberührt. Wir werden jedoch die Forderung nicht einziehen, solange der Besteller seinen Zahlungsverpflichtungen aus den vereinnahmten Erlösen nachkommt, nicht in Zahlungsverzug ist und insbesondere kein Antrag auf Eröffnung eines Insolvenzverfahrens gestellt ist oder Zahlungseinstellung vorliegt.
      </p>
      <p>
        (4) Die Be- und Verarbeitung oder Umbildung der Kaufsache durch den Besteller erfolgt stets Namens und im Auftrag für uns. In diesem Fall setzt sich das Anwartschaftsrecht des Bestellers an der Kaufsache an der umgebildeten Sache fort. Sofern die Kaufsache mit anderen, uns nicht gehörenden Gegenständen verarbeitet wird, erwerben wir das Miteigentum an der neuen Sache im Verhältnis des objektiven Wertes unserer Kaufsache zu den anderen bearbeiteten Gegenständen zur Zeit der Verarbeitung. Dasselbe gilt für den Fall der Vermischung. Sofern die Vermischung in der Weise erfolgt, dass die Sache des Bestellers als Hauptsache anzusehen ist, gilt als vereinbart, dass der Besteller uns anteilmäßig Miteigentum überträgt und das so entstandene Alleineigentum oder Miteigentum für uns verwahrt. Zur Sicherung unserer Forderungen gegen den Besteller tritt der Besteller auch solche Forderungen an uns ab, die ihm durch die Verbindung der Vorbehaltsware mit einem Grundstück gegen einen Dritten erwachsen; wir nehmen diese Abtretung schon jetzt an.
      </p>
      <p>
        (5) Wir verpflichten uns, die uns zustehenden Sicherheiten auf Verlangen des Bestellers freizugeben, soweit ihr Wert die zu sichernden Forderungen um mehr als 20 % übersteigt.
      </p>

      <h4>
        § 9 Gewährleistung und Mängelrüge sowie Rückgriff/Herstellerregress
      </h4>
      <p>
        (1) Gewährleistungsrechte des Bestellers setzen voraus, dass dieser seinen nach § 377 HGB geschuldeten Untersuchungs- und Rügeobliegenheiten ordnungsgemäß nachgekommen ist.
      </p>
      <p>
        (2) Mängelansprüche verjähren in 12 Monaten nach erfolgter Ablieferung der von uns gelieferten Ware bei unserem Besteller. Vorstehende Bestimmungen gelten nicht, soweit das Gesetz gemäß § 438 Abs. 1 Nr. 2 BGB (Bauwerke und Sachen für Bauwerke), § 479 Absatz 1 BGB (Rückgriffsanspruch) und § 634a Absatz 1 BGB (Baumängel) längere Fristen zwingend vorschreibt. Vor etwaiger Rücksendung der Ware ist unsere Zustimmung einzuholen.
      </p>
      <p>
        (3) Sollte trotz aller aufgewendeter Sorgfalt die gelieferte Ware einen Mangel aufweisen, der bereits zum Zeitpunkt des Gefahrübergangs vorlag, so werden wir die Ware, vorbehaltlich fristgerechter Mängelrüge nach unserer Wahl nachbessern oder Ersatzware liefern. Es ist uns stets Gelegenheit zur Nacherfüllung innerhalb angemessener Frist zu geben.  Rückgriffsansprüche bleiben von vorstehender Regelung ohne Einschränkung unberührt.
      </p>
      <p>
        (4) Schlägt die Nacherfüllung fehl, kann der Besteller – unbeschadet etwaiger Schadensersatzansprüche – vom Vertrag zurücktreten oder die Vergütung mindern.
      </p>
      <p>
        (5) Mängelansprüche bestehen nicht bei nur unerheblicher Abweichung von der vereinbarten Beschaffenheit, bei nur unerheblicher Beeinträchtigung der Brauchbarkeit, bei natürlicher Abnutzung oder Verschleiß wie bei Schäden, die nach dem Gefahrübergang infolge fehlerhafter oder nachlässiger Behandlung, übermäßiger Beanspruchung, ungeeigneter Betriebsmittel, mangelhafter Bauarbeiten, ungeeigneten Baugrundes oder aufgrund besonderer äußerer Einflüsse entstehen, die nach dem Vertrag nicht vorausgesetzt sind. Werden vom Besteller oder Dritten unsachgemäß Instandsetzungsarbeiten oder Änderungen vorgenommen, so bestehen für diese und die daraus entstehenden Folgen ebenfalls keine Mängelansprüche.
      </p>
      <p>
        (6) Ansprüche des Bestellers wegen der zum Zweck der Nacherfüllung erforderlichen Aufwendungen, insbesondere Transport-, Wege-, Arbeits- und Materialkosten, sind ausgeschlossen, soweit die Aufwendungen sich erhöhen, weil die von uns gelieferte Ware nachträglich an einen anderen Ort als die Niederlassung des Bestellers verbracht worden ist.
      </p>
      <p>
        (7) Rückgriffsansprüche des Bestellers gegen uns bestehen nur insoweit, als der Besteller mit seinem Abnehmer keine über die gesetzlich zwingenden Mängelansprüche hinausgehenden Vereinbarungen getroffen hat. Für den Umfang des Rückgriffsanspruches des Bestellers gegen den Lieferer gilt ferner Absatz 6 entsprechend.
      </p>

      <h4>
        § 10 Produkteigenschaften
      </h4>
      <p>
        (1) Unsere Produkte sind für den Einsatz in trockenen Wohnräumen ausgelegt. Für Schäden, die aus dem Einsatz in davon abweichenden Räumlichkeiten oder im Freien resultieren, übernehmen wir keine Haftung.
      </p>
      <p>
        (2) Unsere Produkte sind für die Verarbeitung durch qualifizierte Fachkräfte ausgelegt. Für Schäden, die aus der unsachgemäßen Verarbeitung resultieren, übernehmen wir keine Haftung.
      </p>
      <p>
        (3) Bei Produkten, die aus mehreren Komponenten bestehen, sind geringfügige Farbunterschiede zwischen diesen Komponenten kein Grund zur Beanstandung.
      </p>
      <p>
        (4) Der Besteller übernimmt die Verantwortung für die sachgemäße Verarbeitung der Ware mit anderen Produkten (z.B. Vorhänge). Dabei stellen die von uns angegebenen Mengen an Trägern und Ringen nur Empfehlungen da. Insbesondere bei größeren Anlagen und schweren Stoffen ist dies durch den Besteller zu prüfen.
      </p>
      <p>
        (5) Beschädigungen beim Zusammenwirken mit fremden Produkten, z.B. Ösen, erkennen wir nicht an.
      </p>

      <h4>
        § 11 Sonstiges
      </h4>
      <p>
        (1) Dieser Vertrag und die gesamten Rechtsbeziehungen der Parteien unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (CISG).
      </p>
      <p>
        (2) Erfüllungsort und ausschließlicher Gerichtsstand und für alle Streitigkeiten aus diesem Vertrag ist unser Geschäftssitz, sofern sich aus der Auftragsbestätigung nichts anderes ergibt.
      </p>
      <p>
        (3) Alle Vereinbarungen, die zwischen den Parteien zwecks Ausführung dieses Vertrages getroffen werden, sind in diesem Vertrag schriftlich niedergelegt.
      </p>
      <p>
        (4) Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder werden oder eine Lücke enthalten, so bleiben die übrigen Bestimmungen hiervon unberührt. Die Parteien verpflichten sich, anstelle der unwirksamen Regelung eine solche gesetzlich zulässige Regelung zu treffen, die dem wirtschaftlichen Zweck der unwirksamen Regelung am nächsten kommt, bzw. diese Lücke ausfüllt.
      </p>

      <p>
        Steinhagen, August 2010
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'agb-view',
}
</script>

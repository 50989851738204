var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.config)?_c('div',{staticClass:"config-choice--wrap"},[_c('showcase-card',{staticClass:"mb-3",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('article.basicOptions'))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('socket-choice',{staticClass:"mb-2",on:{"input":function (direction) { return _vm.updateConfig('SocketDirection', direction); }}}),_c('rod-length',{staticClass:"mb-2",on:{"input":function (ref) {
	var key = ref.key;
	var value = ref.value;

	return _vm.updateConfig(key, value);
}}}),_c('bracket-choice',{on:{"input":function (bracket) { return _vm.updateConfig('BracketSelected', bracket); }}})]},proxy:true}],null,false,3795103336)}),_c('ring-choice',{staticClass:"mb-3",on:{"input":function (ref) {
	var key = ref.key;
	var value = ref.value;

	return _vm.updateConfig(key, value);
}}}),_c('integral-choice',{staticClass:"mb-3",on:{"input":function (ref) {
	var key = ref.key;
	var value = ref.value;

	return _vm.updateConfig(key, value);
}}}),_c('panel-carriage-choice',{staticClass:"mb-3"}),_c('end-pieces',{staticClass:"mb-3",on:{"input":_vm.updateEndPieces}}),_c('cord-options',{staticClass:"mb-3",on:{"input":function (ref) {
	var key = ref.key;
	var value = ref.value;

	return _vm.updateConfig(key, value);
}}}),_c('bt-toast',{ref:"toast"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
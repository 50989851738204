<template>
  <div class="article-downloads--wrap">
    <showcase-card>
      <template #title>
        {{ $t('assembly.title') }}
      </template>

      <template #content>
        <div
          class="text-primary d-flex align-items-center mb-2"
          @click="showPartList = true"
        >
          <i class="material-icons me-2">list_alt</i>
          {{ $t('article.components') }}
        </div>

        <a
          v-for="({ href, icon, label }, i) in downloads"
          :key="i"
          :href="href"
          target="_blank"
          class="d-flex align-items-center text-decoration-none"
          :class="{ 'mb-2': i < downloads.length - 1 }"
        >
          <i class="material-icons me-2">{{ icon }}</i>
          <span>{{ label }}</span>
        </a>
      </template>
    </showcase-card>

    <bt-modal
      :is-visible="showPartList"
      :title="$t('article.components')"
      @close="showPartList = false"
    >
      <part-list class="mx-n3" />
    </bt-modal>
  </div>
</template>

<script>
import Api from '@/api'
import BtModal from '@/components/BtModal'
import PartList from './PartList'
import ShowcaseCard from '@/components/ShowcaseCard'

export default {
  name: 'article-downloads',

  components: {
    BtModal,
    PartList,
    ShowcaseCard,
  },

  data () {
    return {
      showPartList: false,
    }
  },

  computed: {
    // currently loaded article
    article () {
      return this.$store.state.article
    },

    // downloads related to the current article prepared for the view
    downloads () {
      const downloads = [
        { key: 'AssemblyPdf', label: this.$t('assembly.assemblyInstruction') },
        { key: 'AssemblyIntegralPdf', label: this.$t('assembly.assemblyIntegralInstruction') },
        { key: 'ProductInfo', label: this.$t('assembly.productInfo') },
        { key: 'AssemblyVideo', label: this.$t('assembly.assemblyVideo'), isVideo: true },
      ]

      return downloads.map(({ key, label, isVideo = false }) => this.article[key]
        ? {
            label,
            icon: isVideo ? 'play_circle_outline' : 'download',
            href: `${Api.baseUrl}/${isVideo ? 'videos' : 'download/pdf'}/${this.article[key]}`,
          }
        : null).filter(x => x)
    }
  },
}
</script>

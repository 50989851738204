<template>
  <div class="part-list--wrap">
    <ul class="list-group list-group-flush">
      <li
        v-for="part in partList"
        :key="part.ArticleNr"
        class="list-group-item"
      >
        <div class="row d-flex">
          <div class="col-7">
            <div class="part-name d-flex">
              <span class="me-1">{{ part.Count }}x</span>{{ part.Name }}
            </div>
            <div>
              #{{ part.ArticleNr }}
            </div>
            <div>
              {{ part.UnitPrice | priceWithSurcharge }}&nbsp;€
            </div>
            <div
              v-if="part.Length"
              class="d-flex align-items-center"
            >
              <span class="material-icons me-1">
                straighten
              </span>
              {{ part.Length }} cm
            </div>
            <div
              v-if="part.ColorName"
              class="d-flex align-items-center"
            >
              <span class="material-icons me-1">
                palette
              </span>
              {{ part.ColorName }}
            </div>
          </div>
          <div class="col-5 d-flex align-items-center">
            <proxy-image
              :src="`/images/${part.Picture}`"
              class="part-image img-fluid"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ProxyImage from '@/components/ProxyImage'

export default {
  name: 'part-list',

  components: {
    ProxyImage,
  },

  computed: {
    // parts of the loaded article combined with the parts of the currently
    // chosen endpieces
    partList () {
      const parts = this.$store.state.config?.PartList || []
      const endPieces = this.$store.state.config?.FinialPartList || []
      return [...parts, ...endPieces]
    },
  },
}
</script>

<style lang="scss">
  .part-list--wrap {
    .material-icons {
      font-size: 18px;
    }

    .part-name {
      font-family: 'AgfaRotisSansSerif-Bold', sans-serif;
    }
  }
</style>

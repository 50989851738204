var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.config.FinialList.length > 0 && _vm.config.SocketDirection !== 'Both')?_c('div',{staticClass:"end-pieces--wrap"},[_c('showcase-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('article.endPieces.title'))+" ")]},proxy:true},{key:"title-append",fn:function(){return [_c('button',{staticClass:"btn btn-sm p-0 d-flex align-items-center",on:{"click":function($event){_vm.showDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('article.endPieces.edit'))+" "),_c('span',{staticClass:"material-icons ms-2"},[_vm._v(" settings ")])])]},proxy:true},{key:"content",fn:function(){return [(_vm.selectedEndPiece)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_vm._v(" "+_vm._s(_vm.$t('article.endPieces.choice'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.selectedEndPiece.Name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t(("article.endPieces.directions." + (_vm.config.FinialDirection))))+" ")]),_c('div',{staticClass:"col-6"},[_c('proxy-image',{staticClass:"img-fluid",attrs:{"src":("/images/" + (_vm.selectedEndPiece.Picture))}})],1)]):_vm._e()]},proxy:true}],null,false,1083029145)}),_c('bt-modal',{staticClass:"end-pieces--dialog",attrs:{"is-visible":_vm.showDialog,"body-class":"p-0","title":_vm.$t('article.endPieces.title')},on:{"close":function($event){_vm.showDialog = false}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('button',{staticClass:"btn btn-dark",on:{"click":_vm.chooseEndPiece}},[_vm._v(" "+_vm._s(_vm.$t('article.endPieces.choose'))+" ")])]},proxy:true}],null,false,1317543816)},[_c('div',{staticClass:"directions d-flex justify-content-between"},_vm._l((_vm.directions),function(ref,i){
var disabled = ref.disabled;
var label = ref.label;
var name = ref.name;
return _c('button',{key:i,staticClass:"btn btn-sm py-2",class:{ 'btn-dark': _vm.direction === name },attrs:{"disabled":disabled},on:{"click":function($event){_vm.direction = name}}},[_vm._v(" "+_vm._s(label)+" ")])}),0),(_vm.direction !== 'Without')?_c('div',{staticClass:"px-3"},_vm._l((_vm.config.FinialList),function(endPiece,i){return _c('div',{key:endPiece.Id,staticClass:"row py-3",class:{
          'bg-light text-primary': _vm.selection && _vm.selection.Id === endPiece.Id,
          'border-bottom': i < _vm.config.FinialList.length - 1,
        },on:{"click":function($event){_vm.selection = endPiece}}},[_c('div',{staticClass:"col-4 d-flex align-items-center"},[_c('proxy-image',{staticClass:"img-fluid",attrs:{"src":("/images/" + (endPiece.Picture))}})],1),_c('div',{staticClass:"col-8"},[_vm._v(" "+_vm._s(endPiece.Name)+" "),_c('br'),_vm._v(" "+_vm._s(endPiece.ColorName)+" ("+_vm._s(endPiece.Color)+") "),_c('br'),_vm._v(" "+_vm._s(_vm._f("priceWithSurcharge")(endPiece.Price))+" € ")])])}),0):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="load-config-dialog--wrap">
    <bt-modal
      :title="$t('userConfigs.title')"
      :is-visible="dialogVisible"
      class="load-config-dialog"
      @close="dialogVisible = false"
    >
      <div v-if="userConfigs.length === 0">
        {{ $t('userConfigs.noSavedConfigs') }}
      </div>

      <div v-else-if="articles.length > 0">
        <div
          v-for="userConfig in userConfigs"
          :key="userConfig._id"
          class="user-config py-2"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center article-name">
              <span>
                {{ userConfig.title }} <br>
                {{ userConfig.article.ArticleName }} - #{{ userConfig.article.ArticleNr }}
              </span>
            </div>

            <div class="entry-actions">
              <span
                v-if="entryToRemove !== userConfig._id"
                class="material-icons"
                @click="entryToRemove = userConfig._id"
              >
                delete
              </span>
              <span
                class="material-icons ms-2"
                @click="goToArticle(userConfig.article.ArticleNr, userConfig.color, userConfig._id)"
              >
                drive_folder_upload
              </span>
            </div>
          </div>

          <div
            v-if="entryToRemove === userConfig._id"
            class="mt-2 d-flex align-items-center justify-content-between"
          >
            <div
              class="d-flex align-items-center"
              @click="removeEntry(userConfig._id)"
            >
              <span class="material-icons mr-1">
                delete
              </span>
              {{ $t('userConfigs.confirmDeletion') }}
            </div>

            <div
              class="d-flex align-items-center"
              @click="entryToRemove = null"
            >
              <span class="material-icons me-1">
                cancel
              </span>
              {{ $t('userConfigs.cancelDeletion') }}
            </div>
          </div>
        </div>
      </div>

      <template
        v-if="showSaveArea"
        #actions
      >
        <div class="d-flex flex-column flex-grow-1">
          <div class="form-floating mb-3">
            <input
              id="configName"
              v-model="configName"
              class="form-control"
              autocomplete="off"
              :placeholder="$t('userConfigs.configTitlePlaceholder')"
            >
            <label for="configName">{{ $t('userConfigs.configTitlePlaceholder') }}</label>
          </div>

          <button
            class="btn btn-dark ms-auto"
            :disabled="configName === ''"
            @click="saveCurrentConfig"
          >
            {{ $t('userConfigs.save') }}
          </button>
        </div>
      </template>
    </bt-modal>

    <div @click="dialogVisible = true">
      <slot :user-configs="userConfigs" />
    </div>
  </div>
</template>

<script>
import Api from '@/api'
import BtModal from '@/components/BtModal'

export default {
  name: 'load-config-dialog',

  components: {
    BtModal,
  },

  data () {
    return {
      configName: '',
      dialogVisible: false,
      entryToRemove: null,
    }
  },

  computed: {
    // currently loaded article
    article () {
      return this.$store.state.article
    },

    // all articles
    articles () {
      return this.$store.state.articles
    },

    // currently selected article-color
    color () {
      return this.$store.state.color
    },

    // show an area to save the current article-configuration
    showSaveArea () {
      return this.article && this.color
    },

    /**
     * Saved configurations of the user combined with the related articles so
     * we're able to display additional information like the articlename.
     *
     * @returns {array}
     */
    userConfigs () {
      return this.$store.state.userConfigs
        .filter(({ etrackState }) => !etrackState)
        .map(config => (
          { ...config, article: this.articles.find(({ ArticleNr }) => ArticleNr === config.articleNr) }
        ))
    },
  },

  async mounted () {
    this.$store.dispatch('loadUserConfigs')
  },

  methods: {
    /**
     * Redirects to the detail-page of an article with the given articlenumber,
     * color where the chosen configuration should get loaded, applied.
     *
     * @param {string} articleNr
     * @param {string} color
     * @param {string} configId
     * @returns {void}
     */
    goToArticle (articleNr, color, configId) {
      this.$router.replace({ name: 'Article', params: { articleNr, color, configId } })
      this.dialogVisible = false
    },

    /**
     * Removes the configuration with the given id.
     *
     * @param {string} id
     * @returns {Promise}
     */
    async removeEntry (id) {
      await Api.deleteUserConfig(id)
      this.entryToRemove = null
      this.$store.dispatch('loadUserConfigs')
    },

    /**
     * Saves the current user-configuration, resets the internal state.
     *
     * @returns {Promise}
     */
    async saveCurrentConfig () {
      const res = await Api.storeConfig()

      if (!res.ok) {
        return
      }

      const config = await res.json()

      await Api.createUserConfig({
        articleNr: this.article.Articlenr,
        color: this.color,
        config,
        title: this.configName,
      })

      this.configName = ''
      this.$store.dispatch('loadUserConfigs')
    }
  },
}
</script>

<style lang="scss">
  .load-config-dialog {
    .user-config {
      cursor: pointer;
      border-bottom: 1px solid $gray-200;

      &:last-child {
        border-bottom: none;
      }

      .entry-actions {
        min-width: 70px;
        text-align: right;
      }
    }
  }
</style>

<template>
  <div
    v-if="config.SocketsEnabled"
    class="socket-choice--wrap"
  >
    <div class="form-floating flex-grow-1">
      <select
        id="socketChoice"
        :value="config.SocketDirection"
        class="form-select"
        @input="e => $emit('input', e.target.value)"
      >
        <option
          v-for="socket in socketDirections"
          :key="socket"
          :value="socket"
          :selected="socket === config.SocketDirection"
        >
          {{ $t('article.socket.' + socket.toLowerCase()) }}
        </option>
      </select>

      <label
        for="socketChoice"
        class="mb-1"
      >
        {{ $t('article.socket.label') }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'socket-choice',

  computed: {
    // current article-configuration
    config () {
      return this.$store.state.config
    },

    // possible directions
    socketDirections () {
      return ['Without', 'Left', 'Right', 'Both']
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-choice--wrap"},[_c('div',{staticClass:"slider--wrap"},[(_vm.isCube)?_c('div',{ref:"slider",staticClass:"swiper main-swiper"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.colorOptions),function(ref){
var image = ref.image;
var label = ref.label;
var value = ref.value;
return _c('div',{key:("slide_" + value),staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-zoom-container"},[_c('proxy-image',{staticClass:"img-fluid",attrs:{"src":image,"alt":label}})],1)])}),0)]):_c('div',{ref:"slider",staticClass:"swiper nav-swiper"},[(_vm.color && _vm.colorOptions.length > 0)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"color-label mb-2"},[_vm._v(" "+_vm._s(_vm.colorOptions.find(function (ref) {
	var value = ref.value;

	return value === _vm.color;
}).label)+" ")])]):_vm._e(),_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.colorOptions),function(ref){
var image = ref.image;
var label = ref.label;
var value = ref.value;
return _c('div',{key:("nav_" + value),staticClass:"swiper-slide"},[_c('proxy-image',{staticClass:"img-fluid",attrs:{"src":image,"alt":label}})],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="navbar-top--wrap">
    <nav class="navbar fixed-top navbar-dark bg-dark">
      <div class="container-fluid">
        <router-link :to="{ name: 'Categories' }">
          <img
            src="@/assets/gfx/interstilLogoInv.svg"
            class="img-fluid"
          >
        </router-link>

        <div
          v-if="user"
          :key="user._id"
          class="ms-auto d-flex"
        >
          <load-config-dialog v-slot="{ userConfigs }">
            <button
              type="button"
              class="btn btn-nav"
            >
              <span class="material-icons nav-icon">
                save
              </span>
              <span class="badge rounded-pill bg-primary">
                {{ userConfigs.length }}
              </span>
            </button>
          </load-config-dialog>

          <favorite-dialog v-slot="{ favorites }">
            <button
              type="button"
              class="btn btn-nav ms-3"
              @click="$store.commit('setFavoritesVisible', true)"
            >
              <span class="material-icons">
                star
              </span>
              <span class="badge rounded-pill bg-primary">
                {{ favorites.length }}
              </span>
            </button>
          </favorite-dialog>

          <button
            type="button"
            class="btn btn-nav ms-3"
            @click="searchDialogVisible = true"
          >
            <span class="material-icons">
              search
            </span>
          </button>

          <search-dialog v-model="searchDialogVisible" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import FavoriteDialog from '@/components/FavoriteDialog'
import LoadConfigDialog from '@/components/LoadConfigDialog'
import SearchDialog from '@/components/SearchDialog'

export default {
  name: 'navbar-top',

  components: {
    FavoriteDialog,
    LoadConfigDialog,
    SearchDialog,
  },

  data () {
    return {
      searchDialogVisible: false,
    }
  },

  computed: {
    user () {
      return this.$store.state.user
    },
  },
}
</script>

<style lang="scss">
  .navbar-top--wrap {
    nav {
      height: $navHeight;

      img {
        height: calc($navHeight - 1rem);
      }

      .btn-nav {
        color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0.25rem;

        .badge {
          position: absolute;
          top: -5px;
          right: -10px;
        }
      }
    }
  }
</style>

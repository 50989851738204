<template>
  <div class="showcase-card--wrap card">
    <div class="card-title p-2 mb-0 d-flex align-items-center justify-content-between">
      <h6 class="mb-0">
        <slot name="title" />
      </h6>

      <slot name="title-append" />
    </div>
    <div
      v-if="$slots.content"
      class="card-body"
      :class="contentClass"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'showcase-card',

  props: {
    noContentPadding: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    contentClass () {
      return this.noContentPadding ? 'p-0' : 'p-2'
    }
  },
}
</script>

<style lang="scss">
  .showcase-card--wrap {
    overflow: hidden;

    .card-title {
      background-color: $gray-200;
    }
  }
</style>

<template>
  <div
    class="article-index--wrap"
    :class="{ 'disabled swiper-no-swiping': routeGetsChanged }"
  >
    <div
      v-if="article"
      class="content-header container-fluid pt-2"
      :class="{ 'scrolled swiper-no-swiping': scrollY > 90 }"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="m-0 text-truncate">
          {{ articleName }}
        </h1>

        <div
          v-if="config && config.TotalPrice"
          class="price-preview d-flex align-items-center"
        >
          {{ config.TotalPrice | priceWithSurcharge }}&nbsp;€
        </div>
      </div>

      <div class="content-subheader d-flex align-items-center">
        <bt-popover
          :options="pagePopoverOptions"
          class=" me-auto"
        >
          <span class="material-icons text-dark">
            info
          </span>
        </bt-popover>

        <span
          class="material-icons text-dark me-2"
          @click="resetDialogVisible = true"
        >
          restart_alt
        </span>

        <bt-popover :options="popoverOptions">
          <span class="material-icons text-dark">
            info
          </span>
        </bt-popover>
      </div>

      <image-choice
        is-cube
        :style="{
          transform: `scale(${imageScale}) translateY(${(1 - imageScale) * 50}%)`,
        }"
      />

      <div
        v-if="config && config.TotalPrice"
        class="price--scrolled"
      >
        {{ config.TotalPrice | priceWithSurcharge }}&nbsp;€
      </div>
    </div>

    <div
      v-if="article"
      class="content-wrap container-fluid"
    >
      <variant-choice class="mb-3" />
      <config-choice />
      <downloads class="mb-3" />
    </div>

    <bt-toast ref="toast" />
    <reset-config-dialog v-model="resetDialogVisible" />
  </div>
</template>

<script>
import Api from '@/api'
import ArticleRoute from '@/mixins/ArticleRoute'
import BtPopover from '@/components/BtPopover'
import BtToast from '@/components/BtToast'
import ConfigChoice from './ConfigChoice'
import Downloads from './Downloads'
import ImageChoice from './ImageChoice'
import ResetConfigDialog from '@/components/ResetConfigDialog'
import VariantChoice from './VariantChoice'

export default {
  name: 'catalog-showcase',

  components: {
    BtPopover,
    BtToast,
    ConfigChoice,
    Downloads,
    ImageChoice,
    ResetConfigDialog,
    VariantChoice,
  },

  mixins: [
    ArticleRoute,
  ],

  data () {
    return {
      resetDialogVisible: false,
      routeGetsChanged: false,
      scrollWrap: null,
      scrollY: 0,
      imageScale: 1,
    }
  },

  computed: {
    // currently loaded article
    article () {
      return this.$store.state.article
    },

    // all articles
    articles () {
      return this.$store.state.articles
    },

    // full name of the current article
    articleName () {
      if (!this.article) return ''

      const articleRef = this.$store.state.articles.find(({ ArticleNr }) => ArticleNr === this.article.Articlenr)
      return articleRef?.ArticleName || this.article.Name
    },

    // current article-configuration
    config () {
      return this.$store.state.config
    },

    // route-params
    params () {
      return this.$route.params
    },

    /**
     * Builds the content of the popover for the article-/page-information.
     *
     * If there's an article- and page-information we want to display those
     * seperated in the title-, content slots. Otherwise we just show the
     * article-info (which always exists) within the content-slot without a
     * title.
     *
     * @returns {object}
     */
    pagePopoverOptions () {
      const possiblePageInfos = [
        { field: 'CatalogPageF1', label: this.$t('infos.pageInfoF1') },
        { field: 'CatalogPageInterstil', label: this.$t('infos.pageInfo') },
        { field: 'CatalogPageW', label: this.$t('infos.pageInfoW') },
      ]

      const pageInfo = possiblePageInfos.find(({ field }) => this.article[field] !== null)
      const articleInfo = `${this.$t('infos.pageInfoTitle')}: ${this.article.Articlenr}`

      return {
        title: pageInfo ? articleInfo : '',
        content: pageInfo ? `${pageInfo.label} ${this.article[pageInfo.field]}` : articleInfo
      }
    },

    // option-overrides for the info-popover
    popoverOptions () {
      return {
        html: true,
        title: this.$t('infos.priceInfoTitle'),
        content: `${this.$t('infos.priceInfo')}<br>${this.$t('infos.currentCatalog')}`
      }
    },

    // fields that couldn't get restored when the variant/color was changed
    restoreErrors () {
      return this.config?.RestoreErrors || []
    },

    // saved configurations of the user
    userConfigs () {
      return this.$store.state.userConfigs
    },
  },

  watch: {
    restoreErrors () {
      this.handleRestoreErrors()
    },

    '$route' (to, from) {
      this.onRouteChange(to, from)
    },
  },

  mounted () {
    this.loadArticle()
    this.scrollWrap = document.querySelector('main')
    this.scrollWrap.addEventListener('scroll', this.onScrollContent)
  },

  destroyed () {
    this.$store.commit('setArticle', null)
    this.$store.commit('setColor', null)
    this.$store.commit('setConfig', null)
    this.scrollWrap.removeEventListener('scroll', this.onScrollContent)
  },

  methods: {
    /**
     * Loads the article-dataset based on the articlenumber given as route-
     * parameter. If everything is fine, the color gets set, the related config
     * gets loaded.
     *
     * @returns {Promise}
     */
    async loadArticle () {
      const { articleNr, color, configId } = this.params

      if (!articleNr) {
        return
      }

      this.$store.commit('setArticle', null)
      this.$store.commit('setColor', null)
      this.$store.commit('setLoading', true)
      await this.$store.dispatch('loadArticleData', articleNr)

      // if no color is specified, we just use the first available of the
      // article since it's required for loading a configuration
      this.$store.commit('setColor', color ? color.replace('-', '/') : this.article.Colors[0].Key)

      await this.$store.dispatch('loadConfig')
      configId && await this.applyConfiguration(configId)
      this.$store.commit('setLoading', false)
    },

    /**
     * When the route gets changed, we must react to that based on the content:
     *
     * - new article: reinitialization
     * - same article, changed color: load related config
     *
     * @param {object} to
     * @param {object} from
     * @returns {void}
     */
    async onRouteChange (to, from) {
      // we must reload article-data when the article-number or configuration gets changed
      const articleChanged = !this.article || (this.article.Articlenr !== this.params.articleNr)
      const configChanged = to.params.configId && (to.params.configId !== from.params.configId)

      this.routeGetsChanged = true

      if (articleChanged || configChanged) {
        await this.loadArticle()
      } else if (to.params.color !== from.params.color) {
        // article, config are the same, but the desired color was modified
        this.$store.commit('setColor', to.params.color)
        await this.$store.dispatch('loadConfig')
      }

      this.routeGetsChanged = false
    },

    /**
     * Tracks the vertical scroll-position of the main-content-element to be
     * able to set css-classes based on that.
     *
     * @returns {void}
     */
    onScrollContent () {
      const { height } = this.scrollWrap.getBoundingClientRect()

      this.scrollY = this.scrollWrap.scrollTop
      let scale = 1 - this.scrollWrap.scrollTop / (this.scrollWrap.scrollHeight - height)
      scale < 0.7 && (scale = 0.7)
      scale > 1 && (scale = 1)
      this.imageScale = scale.toFixed(2)
    },

    /**
     * Applies the user-configuration with the given id.
     *
     * @param {string} configId ID of a persisted config that should get loaded
     * @return {Promise}
     */
    async applyConfiguration (configId) {
      this.$store.commit('setLoading', true)

      if (configId) {
        // load configs if not already done by another component
        !this.userConfigs.length && await this.$store.dispatch('loadUserConfigs')
        // get desired user-configuration
        const config = this.userConfigs.find(({ _id }) => _id === configId)?.config || {}
        // configId isn't needed anymore, we want to show the area with the config
        this.$router.replace({ name: 'Article', params: { ...this.$route.params, configId: null } })

        const res = await Api.restoreConfig(config)
        res.ok && this.$store.commit('setConfig', await res.json())
      }

      this.$store.commit('setLoading', false)
    },

    /**
     * Builds a string to display a list of translated error-keys which gets
     * shown in a toast-element.
     *
     * @returns {void}
     */
    handleRestoreErrors () {
      if (this.restoreErrors.length === 0) {
        return
      }

      const content = `${this.$t('article.restoreErrors.info')}\n
        ${this.restoreErrors.map((key) => `\u2022 ${this.$t(`article.restoreErrors.keys.${key}`)}`).join('\n')}
      `

      this.$refs.toast.show(this.$t('article.restoreErrors.title'), content)
    },
  },
}
</script>

<style lang="scss">
  .article-index--wrap {
    .content-header {
      position: sticky;
      top: -90px;
      z-index: 3;
      background-color: $white;
      transition: box-shadow 120ms ease;

      .price-preview {
        font-size: 1.275rem;
        margin-left: 12px;
      }

      .image-choice--wrap {
        transition: transform 120ms ease;
        will-change: transform;
      }

      .price--scrolled {
        position: fixed;
        top: calc($navHeight + 10px);
        right: 10px;
        z-index: 3;
        background-color: $dark;
        color: #fff;
        padding: 0 7px;
        border-radius: 4px;
        transform: scale(0);
        transition: transform 120ms ease;
      }

      &.scrolled {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);

        .price--scrolled {
          transform: scale(1);
        }
      }
    }

    .content-subheader {
      flex-wrap: wrap;

      .divider {
        width: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: '';
          width: 1px;
          height: 12px;
          display: inline-block;
          background-color: rgba($color: #000, $alpha: 0.12)
        }
      }

      .article-page--wrap {
        margin-right: auto;
      }
    }

    .content-wrap {
      flex: 1;
      overflow-y: auto;
      position: relative;
    }

    .image-choice--wrap {
      transition: opacity 320ms ease;
    }

    &.disabled {
      .image-choice--wrap {
        opacity: 0.5;
      }
    }
  }
</style>

<template>
  <div class="rod-choice--wrap d-flex align-items-center">
    <div
      v-if="article.RodCountVariants.length <= 3"
      role="group"
    >
      <button
        v-for="amount in sortedVariants"
        :key="`rodCount_${amount}`"
        class="btn text-lowercase me-1"
        :class="{ 'btn-outline-dark': article.SelectedRodCountVariant === amount }"
        :disabled="article.SelectedRodCountVariant === amount"
        @click="$emit('input', amount)"
      >
        {{ amount }}{{ $t('article.rodVariantSuffix') }}
      </button>
    </div>

    <div
      v-else
      class="form-floating flex-grow-1"
    >
      <select
        id="rodSelect"
        class="form-select"
        @input="e => $emit('input', e.target.value)"
      >
        <option
          v-for="(option, x) in sortedVariants"
          :key="x"
          :value="option"
          :selected="option === article.SelectedRodCountVariant"
        >
          {{ option }}
        </option>
      </select>

      <label for="rodSelect">
        {{ $t('article.variants.rodCount') }}
      </label>
    </div>

    <image-magnifier
      v-if="article.PictureVariant"
      :image="`/images/${article.PictureVariant}`"
      :title="$t('article.rodVariant')"
      class="ms-2"
    />
  </div>
</template>

<script>
import ImageMagnifier from '@/components/ImageMagnifier'

export default {
  name: 'rod-choice',

  components: {
    ImageMagnifier,
  },

  computed: {
    // currently loaded article
    article () {
      return this.$store.state.article
    },

    // possible variants sortes by their value
    sortedVariants () {
      const variants = this.article.RodCountVariants
      return variants.sort()
    }
  },
}
</script>

<style lang="scss">
  .rod-choice--wrap {
    .btn:active,
    .btn:hover {
      background-color: $white;
    }
  }
</style>

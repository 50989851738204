<template>
  <div class="login-view--wrap container-fluid py-3">
    <h1 class="mb-3">
      {{ $t('login.login') }}
    </h1>

    <form @submit.prevent="login">
      <div class="mb-3">
        <label
          class="form-label"
          for="userMail"
        >
          {{ $t('login.email') }}
        </label>

        <input
          id="userMail"
          v-model="userMail"
          required
          type="email"
          class="form-control input-lg"
        >
      </div>

      <div class="mb-3">
        <label
          class="form-label"
          for="userPassword"
        >
          {{ $t('login.password') }}
        </label>

        <input
          id="userPassword"
          v-model="password"
          required
          type="password"
          class="form-control input-lg"
        >
      </div>

      <div
        v-if="error"
        class="alert alert-danger"
        role="alert"
      >
        {{ error }}
      </div>

      <div class="d-flex mt-5">
        <router-link
          :to="{ name: 'Register' }"
          class="btn btn-light"
        >
          {{ $t('login.register') }}
        </router-link>

        <button
          type="submit"
          class="btn btn-success d-flex align-center ms-auto"
        >
          <i class="material-icons me-2">lock_outline</i>
          <span>{{ $t('login.login') }}</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Api from '@/api'

export default {
  name: 'login-view',

  data () {
    return {
      password: '',
      userMail: '',
      error: null,
    }
  },

  methods: {
    /**
     * Performs a login with the currently entered data. If everything is fine,
     * we get the session, redirect the user. Otherwise an info gets shown.
     *
     * @returns {Promise}
     */
    async login () {
      this.error = null

      const res = await Api.login(this.userMail, this.password)

      if (res.ok) {
        await this.$store.dispatch('getSession')
        this.$router.replace({ name: 'Intro' })
      } else {
        const { name } = await res.json()
        this.onLoginError(name)
      }
    },

    /**
     * onLoginError
     *
     * @param {string} error
     * @returns {undefined}
     */
    onLoginError (error) {
      this.password = ''
      this.error = error === 'UserLockedError' ? this.$t('login.error.userLocked') : this.$t('login.error.loginWrong')
    }
  }
}
</script>

import router from './router'

export default {
  baseUrl: '/fhp',
  jsonHeaders: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },

  /**
   * Request-wrap that redirects to the login-view when getting an unautherized-response.
   *
   * @param {string} url
   * @param {object} options
   * @param {string} options.method
   * @param {any} options.body
   * @param {object} options.headers Request-headers to use instead of the json-default
   * @returns {Promise}
   */
  async authFetch (url = '', { method = 'GET', body, headers = this.jsonHeaders } = {}) {
    const res = await fetch(url, {
      headers,
      method,
      body,
      credentials: 'include',
    })

    if (res.status === 401) {
      const noRedirectRoutes = ['Login', 'Register', 'Activate']
      !noRedirectRoutes.includes(router.currentRoute.name) && router.replace({ name: 'Login' })
    }

    return res
  },

  /**
   * Adds a new favorite.
   *
   * @param {string} articleName
   * @param {string} articleNr
   * @param {string} color
   * @param {string} image
   * @returns {Promise}
   */
  async addFavorite (articleName, articleNr, color, image) {
    return await this.authFetch(`${this.baseUrl}/bookmark`, {
      method: 'POST',
      body: JSON.stringify({
        articleName,
        articleNumber: articleNr,
        color,
        image,
      })
    })
  },

  /**
   * Deletes an existing favorite.
   *
   * @param {string} id
   * @returns {Promise}
   */
  async deleteFavorite (id) {
    return await this.authFetch(`${this.baseUrl}/bookmark/${id}`, {
      method: 'DELETE',
    })
  },

  /**
   * Requests the api to add the given panel with the given configuration to
   * the current article.
   *
   * @param {string|number} panel
   * @param {object} config
   * @param {number} config.amount
   * @param {string} config.color
   * @param {number} config.panelLength
   * @returns {Promise}
   */
  async addPanelCarriage (panel, { amount, color, panelLength }) {
    const params = new URLSearchParams({
      articlenr: panel,
      colornr: color,
      length: panelLength,
      count: amount,
    })

    return await this.authFetch(`${this.baseUrl}/api/article/config/panelcarriage/add?${params}`, {
      method: 'POST',
    })
  },

  /**
   * Removes the panel carriage with the given id.
   *
   * @param {string} panelId
   * @returns {Promise}
   */
  async removePanelCarriage (panelId) {
    const params = new URLSearchParams({ guid: panelId })

    return await this.authFetch(`${this.baseUrl}/api/article/config/panelcarriage/remove?${params}`, {
      method: 'POST',
    })
  },

  /**
   * Updates the amount of the panel carriage with the given id.
   *
   * @param {string} panelId
   * @param {number} amount
   * @returns {Promise}
   */
  async setPanelCarriageAmount (panelId, amount) {
    const params = new URLSearchParams({ guid: panelId, count: amount })

    return await this.authFetch(`${this.baseUrl}/api/article/config/panelcarriage/setcount?${params}`, {
      method: 'POST',
    })
  },

  /**
   * Loads the full dataset of the article with the given number.
   *
   * @param {string} articleNr
   * @returns {Promise}
   */
  async getArticle (articleNr) {
    const res = await this.authFetch(`${this.baseUrl}/api/article/${articleNr}`)

    if (res.ok) {
      return await res.json()
    }
  },

  /**
   * Loads the list of all available articles.
   *
   * @returns {Promise}
   */
  async getArticles () {
    const res = await this.authFetch(`${this.baseUrl}/api/article`)
    return res.ok ? (await res.json()).Items : []
  },

  /**
   * Loads the configuration for the desired article-color-combination.
   *
   * @param {string} articleNr
   * @param {string} color
   * @returns {Promise}
   */
  async getConfig (articleNr, color) {
    const res = await this.authFetch(`${this.baseUrl}/api/article/config?articlenr=${articleNr}&color=${color}`)

    if (res.ok) {
      return await res.json()
    }
  },

  /**
   * Loads favorites of the current user. But we just want to use articles,
   * not parts.
   *
   * @returns {Promise}
   */
  async getFavorites () {
    const res = await this.authFetch(`${this.baseUrl}/bookmark`)

    if (res.ok) {
      const favorites = await res.json()
      return favorites.filter(({ isPart }) => !isPart)
    }
  },

  /**
   * Requests an update of the configuration with the given key-, value-pair.
   *
   * @param {string} key
   * @param {any} value
   * @returns {Promise}
   */
  async postConfig (key, value) {
    return await this.authFetch(`${this.baseUrl}/api/article/config`, {
      method: 'POST',
      body: JSON.stringify([{ Key: key, Value: value }])
    })
  },

  /**
   * Requests an object which allows us to restore the current configuration.
   *
   * @returns {Promise}
   */
  async storeConfig () {
    return await this.authFetch(`${this.baseUrl}/api/article/config/store`)
  },

  /**
   * Requests the api the restore a configuration based on the given config.
   *
   * @param {object} storedConfig
   * @returns {Promise}
   */
  async restoreConfig (storedConfig) {
    return await this.authFetch(`${this.baseUrl}/api/article/config/restore`, {
      method: 'POST',
      body: JSON.stringify(storedConfig)
    })
  },

  /**
   * Requests the api to reset the current configuration so the default of the
   * article gets used.
   *
   * @returns {Promise}
   */
  async resetArticleConfiguration () {
    return await this.authFetch(`${this.baseUrl}/api/article/config/reset`)
  },

  /**
   * setVariant
   *
   * @param {object} variantConfig
   * @returns {Promise}
   */
  async setVariant (variantConfig) {
    const res = await this.authFetch(`${this.baseUrl}/api/article/setvariant`, {
      method: 'POST',
      body: JSON.stringify(variantConfig)
    })

    if (res.ok) {
      return await res.json()
    }
  },

  /**
   * Loads the catalog-entries.
   *
   * @returns {Promise}
   */
  async getCatalog () {
    return this.authFetch(`${this.baseUrl}/api/catalog/programs`)
  },

  /**
   * Tries to get the session of the current user.
   *
   * @returns {Promise}
   */
  async getSession () {
    return this.authFetch(`${this.baseUrl}/user/session`)
  },

  /**
   * Creates a new user-configuration.
   *
   * @param {object} userConfig
   * @param {string} userConfig.articleNr
   * @param {string} userConfig.color
   * @param {object} userConfig.config
   * @param {string} userConfig.title
   * @returns {Promise}
   */
  async createUserConfig ({ articleNr, color, config, title }) {
    return this.authFetch(`${this.baseUrl}/showcase/create`, {
      method: 'POST',
      body: JSON.stringify({ articleNr, color, config, title })
    })
  },

  /**
   * Removes the user-configuration with the given id.
   *
   * @param {string} id
   * @returns {Promise}
   */
  async deleteUserConfig (id) {
    return this.authFetch(`${this.baseUrl}/showcase`, {
      method: 'DELETE',
      body: JSON.stringify({ id })
    })
  },

  /**
   * Loads the configurations saved by the user.
   *
   * @returns {Promise}
   */
  async loadUserConfigs () {
    const res = await this.authFetch(`${this.baseUrl}/showcase`)
    return res.ok ? await res.json() : []
  },

  /**
   * Performs a login with the given data.
   *
   * @param {string} email
   * @param {string} password
   * @returns {Promise}
   */
  async login (email, password) {
    return await fetch(`${this.baseUrl}/user/login`, {
      method: 'PUT',
      headers: this.jsonHeaders,
      credentials: 'include',
      body: JSON.stringify({ email, password, type: 'mobile' }),
    })
  },

  /**
   * Activates the user related to the given activation-id, sets the password.
   *
   * @param {string} id
   * @param {string} password
   * @returns {Promise}
   */
  async activate (id, password) {
    return await fetch(`${this.baseUrl}/user/activation`, {
      method: 'POST',
      headers: this.jsonHeaders,
      body: JSON.stringify({ id, password }),
    })
  },

  /**
   * Registers a new user.
   *
   * @param {object} user
   * @returns {Promise}
   */
  async register (user) {
    return await fetch(`${this.baseUrl}/registration`, {
      method: 'PUT',
      headers: this.jsonHeaders,
      body: JSON.stringify(user),
    })
  },

  /**
   * Loads the public captcha-key.
   *
   * @returns {Promise}
   */
  async recaptchaKey () {
    return await fetch(`${this.baseUrl}/captchaKey`)
  },
}
